import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { TranscriptTypes } from '../../constants/enums';
import Colors from '../../utils/color';

type TranscriptMessageStyleProps = {
  type: TranscriptTypes;
};

const useStyles = makeStyles<Theme, TranscriptMessageStyleProps>(({}) => ({
  transcriptMessageClass: {
    fontSize: '14px',
    lineHeight: '20px',
    paddingBottom: '4px',
    display: 'flex',
    color: ({ type }) =>
      `${type === TranscriptTypes.AI ? '#1E1E1E' : Colors.guardsmanRed}`,
  },
  sourceClass: {
    width: '1.75rem',
    fontWeight: 'bold',
  },
  contentClass: {
    width: 'calc(100% - 1.75rem)',
  },
}));

interface ITranscriptMessage {
  text: string;
  type: TranscriptTypes;
}

export const TranscriptMessage = ({ text = '•', type }: ITranscriptMessage) => {
  const { transcriptMessageClass, sourceClass, contentClass } = useStyles({
    type,
  });

  return (
    <div className={transcriptMessageClass}>
      <div className={sourceClass}>{type}:&nbsp;</div>
      <div className={contentClass}>{text}</div>
    </div>
  );
};
