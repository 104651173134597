import logger from '../../../utils/logger';
import { SessionEndReasons, SessionStatus } from './sessionBoundary.constants';
import { ISessionBoundaryState } from './sessionBoundary.props';

const initialState: ISessionBoundaryState = {
  checkCreated: false, // Indicate whether a check created on POS or not by checking the check event
  carExit: false, // Indicate whether the car exits the drive-through or not by detecting a car_exit event
  cartEmpty: true,
  finalOrderSubmitted: false, // Only when checkCreated is true, this state will be used to check whether the check is finalized or not. The check can be seen as finalized by 1. clicking Cancel Order 2. clicking Finish Order 3. clicking Restaurant staff 4. receiving restaurant staff intervention event
  sessionId: '', // Current session id in session boundary
  sessionStatus: '', // Current session status
  sessionStartReason: '', // Trigger of starting a session
  sessionEndReason: '', // Trigger of ending a session
  endedSessionId: '', // Temperately store the session id ended by SB before a new session start. Used by interception reason dialog to send info event after session boundaries have been ended but new session not started or back to back session to start an already started session.
  orderMessageOrderId: '', // The inner session id of order event received from the event backend in the current session which comes from the AI.
  orderMessageData: {}, // The date field of order event received from the event backend in the current session which comes from the AI
  isPostCarExitTimerActivated: false, // When car exit happened and agent is still processing the order
  isSessionTimeoutDialogOpen: false, // When agent is idle after receiving a non-AI task (or) AI task after escalation this timeout popup appears
  showOrderSubmissionAlert: false, // When order sent by AI with final flag true is received
};

const checkSessionEnd = (sessionBoundaryState: ISessionBoundaryState) => {
  const { checkCreated, finalOrderSubmitted, carExit, cartEmpty } =
    sessionBoundaryState;
  const noPendingFinalOrder =
    !checkCreated || (checkCreated && finalOrderSubmitted);
  return noPendingFinalOrder && carExit && cartEmpty;
};

const fillEndSessionValues = (
  state: ISessionBoundaryState,
  reason: SessionEndReasons
) => {
  logger.info(
    `Session boundary state is reset when HITL session ends with ${reason}`
  );
  state.sessionStatus = SessionStatus.end;
  state.sessionEndReason = reason;
  state.carExit = false;
  state.endedSessionId = state.sessionId;
  state.sessionId = '';
  state.finalOrderSubmitted = false;
  state.orderMessageOrderId = '';
  state.checkCreated = false;
  state.sessionStartReason = '';
  state.cartEmpty = true;
  state.orderMessageData = {};
  state.isPostCarExitTimerActivated = false;
  state.isSessionTimeoutDialogOpen = false;
};

export { checkSessionEnd, fillEndSessionValues, initialState };
