import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import {
  CartItem,
  getCartModGroupInChildModifierGroups,
} from '../../../utils/cart';
import Colors from '../../../utils/color';
import {
  checkForUnavailableRequiredModifierGroup,
  isItem86edToday,
  ParsedMenuItem,
  ParsedModifierGroup,
} from '../../../utils/menu';
import MultipleSelectionModGroup from './MultipleSelectionModGroup';
import SingularSelectionModGroup from './SingularSelectionModGroup';
import Delayed from "./Delayed";

interface OwnProps {
  modGroup: ParsedModifierGroup;
  cartItem: CartItem;
  selectMenuItemModifier: (
    menuItem: ParsedMenuItem,
    modGroup: ParsedModifierGroup,
    pathToItem: string,
    selected: boolean,
    fromClick?: boolean
  ) => void;
  pathToItem: string;
  isSelectedToFocus?: boolean;
}

export interface IModGroupStyleProps {
  isSelectedToFocus?: boolean;
}

const useStyles = makeStyles<IModGroupStyleProps>()(({ spacing }, { isSelectedToFocus }) => {
  const bgColor = isSelectedToFocus ? Colors.alto : Colors.white;
  return {
    modGroupClass: {
      paddingLeft: spacing(2.5),
      backgroundColor: bgColor,
    }
  }
});

const ModGroup = (props: OwnProps) => {
  const modGroupRef = useRef<HTMLDivElement>(null);
  const {
    modGroup,
    cartItem,
    selectMenuItemModifier,
    pathToItem,
    isSelectedToFocus,
  } = props;

  const {
    classes
  } = useStyles({ isSelectedToFocus });

  useEffect(() => {
    if (isSelectedToFocus) {
      modGroupRef.current?.scrollIntoView();
    }
  }, [isSelectedToFocus]);

  const allModifierUnavailable = !Object.values(modGroup.menuItems).find(
    (menuItem) => {
      if (
        (menuItem.available || isItem86edToday(menuItem)) &&
        !checkForUnavailableRequiredModifierGroup(menuItem.modifierGroups)
      ) {
        return menuItem;
      }
    }
  );
  const cartModGroup = getCartModGroupInChildModifierGroups(modGroup, cartItem);

  useEffect(() => {
    // handle default modifier
    if (!cartModGroup) {
      modGroup.defaultSelectedItemIds?.forEach((itemId) => {
        selectMenuItemModifier(
          modGroup.menuItems[itemId],
          modGroup,
          pathToItem,
          true
        );
      });
    }
  }, [cartModGroup, pathToItem]);

  const isSingleChoice =
    modGroup.minimumSelections === modGroup.maximumSelections &&
    modGroup.maximumSelections === 1;

  const handleModChange = useCallback(
    (innerModGroup: ParsedModifierGroup) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemId = event.target.value;
        const isChecked = isSingleChoice || event.target.checked;
        if (itemId.length > 0) {
          selectMenuItemModifier(
            innerModGroup.menuItems[itemId],
            innerModGroup,
            pathToItem,
            isChecked,
            true
          );
        }
      },
    [isSingleChoice, pathToItem, selectMenuItemModifier]
  );

  const modGroupProps = {
    cartItem,
    key: `${modGroup.id}_main_node`,
    modGroup,
    cartModGroup,
    onChange: handleModChange,
    selectMenuItemModifier,
    isSelectedToFocus,
  };

  return (
    <Delayed>
      <div data-testid="mod-group" className={classes.modGroupClass} ref={modGroupRef}>
        {allModifierUnavailable ? null : (
          <>
            {isSingleChoice ? (
              <SingularSelectionModGroup
                {...modGroupProps}
                pathToItem={pathToItem}
              />
            ) : (
              <MultipleSelectionModGroup
                {...modGroupProps}
                pathToItem={pathToItem}
              />
            )}
          </>
        )}
      </div>
    </Delayed>
  );
};

export default memo(ModGroup);
