import { AnyAction, Middleware } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { DUMMY_RESTAURANT_CODE } from '../constants';
import { AgentTypes, EventTypes } from '../constants/event';
import {
  AudioFrameTransmissionMessage,
  InfoTransmissionMessage,
  messagingActions,
  sendNetworkCallLogs,
} from '../reducers/messagingSlice';
import { checkHealthStatus } from '../redux/features/healthStatusCheck/healthStatusCheck.slice';
import { readEnvVariable } from '../utils';
import { agent } from '../utils/agent';
import { Counter } from '../utils/counter';
import logger from '../utils/logger';
import { getWebsocketUrl, sleep } from '../utils/network';
import { PerfTimer } from '../utils/timer';
import { RootStore } from './store';
import { getMenuVersion } from '../utils/menuVersion';

const isMatchMessagingAction = (action: AnyAction) => {
  const {
    sendMessage,
    sendInfo,
    sendOrder,
    sendLoyalty,
    sendEndSession,
    sendTTSRequest,
    sendError,
    sendAgentFirstActivity,
    sendAgentFirstInterception,
    sendAgentInterception,
    sendStaffInterception,
    sendHITLSessionEnd,
    sendHITLSessionStart,
    sendHITLEventConnection,
    sendCancelOrder,
  } = messagingActions;
  return (
    sendMessage.match(action) ||
    sendInfo.match(action) ||
    sendOrder.match(action) ||
    sendLoyalty.match(action) ||
    sendEndSession.match(action) ||
    sendTTSRequest.match(action) ||
    sendError.match(action) ||
    sendAgentFirstActivity.match(action) ||
    sendAgentFirstInterception.match(action) ||
    sendAgentInterception.match(action) ||
    sendStaffInterception.match(action) ||
    sendHITLSessionEnd.match(action) ||
    sendHITLSessionStart.match(action) ||
    sendHITLEventConnection.match(action) ||
    sendCancelOrder.match(action)
  );
};

const socketMiddleware: Middleware = (store: RootStore) => {
  let eventSocket: WebSocket | undefined = undefined; // Websocket of event backend
  let audioSocket: WebSocket | undefined = undefined; // Websocket of audio backend

  let eventWebSocketConnectionTimer = new PerfTimer({ autoStart: false });
  let audioWebSocketConnectionTimer = new PerfTimer({ autoStart: false });

  let audioWebSocketRetries = 1; // Count of event backend websocket retry
  let eventWebSocketRetries = 1; // Count of audio backend websocket retry

  return (next) => (action) => {
    const {
      restaurant: {
        selectedRestaurantDetails: { restaurantCode: selectedRestaurantCode },
        selectedStage,
      },
      config: { WEBSOCKET_RETRY_MAX_BACKOFF },
      messages: { isConnected },
      user,
      config,
      taskRouter: { taskAssignedSessionId },
    } = store.getState();

    const isEventConnectionEstablished = eventSocket && isConnected;

    const reconnect = async ({
      isAudioWS,
      maxRetries = 9,
    }: {
      isAudioWS: boolean;
      maxRetries?: number;
    }) => {
      if (!store.getState()?.user?.isLoggedIn) {
        return;
      }
      let retries = isAudioWS ? audioWebSocketRetries : eventWebSocketRetries;
      if (retries < maxRetries) {
        let multiple = 1 + Math.random(); // multiple is in the range 1+[0 to 1] = [1 to 2]
        let timeToWait = 2 ** retries * 1000 * multiple;
        await sleep(
          timeToWait > WEBSOCKET_RETRY_MAX_BACKOFF
            ? WEBSOCKET_RETRY_MAX_BACKOFF
            : timeToWait
        );

        const isWSEstablished = isAudioWS
          ? audioSocket && audioSocket.readyState !== audioSocket.CLOSED
          : eventSocket && eventSocket.readyState !== eventSocket.CLOSED;

        if (isWSEstablished || !store.getState()?.user?.isLoggedIn) {
          return;
        }
        // reconnect if lost connection
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `In ${retries} retry to established connection to restaurant specific ${
            isAudioWS ? 'audio' : ''
          } websocket`,
        });
        if (isAudioWS) {
          audioWebSocketRetries += 1;
          audioConnect();
        } else {
          eventWebSocketRetries += 1;
          eventConnect();
        }
      } else {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `Reach ${retries} maximum retry to established connection to restaurant specific ${
            isAudioWS ? 'audio' : ''
          } websocket`,
        });
      }
    };

    const eventConnect = () => {
      const username = user?.userProfile?.username;
      if (!selectedRestaurantCode || !username) {
        return;
      }
      agent.setAgentId(username);

      if (eventSocket) {
        eventSocket.close();
      }

      const eventWebSocketURL = getWebsocketUrl(
        config,
        selectedRestaurantCode,
        selectedStage,
        false
      );

      eventWebSocketConnectionTimer.start();
      eventSocket = new WebSocket(eventWebSocketURL);

      logger.log({
        restaurantCode: selectedRestaurantCode,
        message:
          'Trying to establish connection to restaurant specific websocket',
      });

      eventSocket.onopen = () => {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message:
            'Successfully established connection to restaurant specific websocket',
        });

        store.dispatch(messagingActions.connectionEstablished());

        store.dispatch(
          sendNetworkCallLogs({
            url: eventWebSocketURL,
            duration: eventWebSocketConnectionTimer.stop(),
            message:
              'restaurant specific websocket connection established successfully',
            via: 'websocket',
          })
        );

        const payload: Partial<InfoTransmissionMessage> = {
          data: { type: 'METRIC', message: 'Test event websocket signal' },
        };
        store.dispatch(messagingActions.sendInfo(payload as any));
      };

      let messages: any[] = [];
      let audioMessageSample: AudioFrameTransmissionMessage | null = null;
      eventSocket.onmessage = (event: any) => {
        const data = JSON.parse(event.data);
        if (data) {
          messages.push(data);
        }
      };

      setInterval(() => {
        if (messages.length) {
          const filteredMessages: any[] = [],
            nonAudioMessages: any[] = [];

          messages.forEach((message) => {
            if (
              !taskAssignedSessionId ||
              (taskAssignedSessionId &&
                message.session_id === taskAssignedSessionId)
            ) {
              filteredMessages.push(message);
              if (message.event !== EventTypes.audio) {
                nonAudioMessages.push(message);
              } else {
                if (!audioMessageSample) audioMessageSample = message;
              }
            }
          });

          if (nonAudioMessages.length) {
            logger.log({
              restaurantCode: selectedRestaurantCode,
              message:
                'Process non-audio message via restaurant specific websocket',
              moreInfo: JSON.stringify(nonAudioMessages),
            });
          }

          const { taskRouter, sessionBoundary, ai } = store.getState();
          store.dispatch(
            messagingActions.messageReceived({
              messages: filteredMessages,
              taskRouter,
              sessionBoundarySessionId: sessionBoundary.sessionId,
              ai,
            })
          );
        }
        messages = [];
      }, 100);

      // Sampling audio event every 10 second
      setInterval(() => {
        if (audioMessageSample) {
          logger.log({
            restaurantCode: selectedRestaurantCode,
            message: `Process audio message via restaurant specific websocket`,
            moreInfo: JSON.stringify(audioMessageSample),
          });
          audioMessageSample = null;
        }
      }, 10000);

      eventSocket.onclose = (event: any) => {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: 'Restaurant specific websocket is closed unintentionally',
          moreInfo: JSON.stringify(event, ['code', 'reason', 'wasClean']),
        });
        if (eventSocket?.readyState === eventSocket?.CLOSED) {
          eventWebSocketConnectionTimer.stop();
        }
        store.dispatch(messagingActions.connectionLost());
        reconnect({ isAudioWS: false, maxRetries: 5 });
      };

      store.dispatch(checkHealthStatus());
    };

    const audioConnect = () => {
      const username = user?.userProfile?.username;

      if (!selectedRestaurantCode || !username) {
        return;
      }

      agent.setAgentId(username);

      if (audioSocket) {
        audioSocket.close();
      }

      const audioWebSocketURL = getWebsocketUrl(
        config,
        selectedRestaurantCode,
        selectedStage,
        true
      );

      audioWebSocketConnectionTimer.start();
      audioSocket = new WebSocket(audioWebSocketURL);

      logger.log({
        restaurantCode: selectedRestaurantCode,
        message: `Trying to establish connection to restaurant specific audio websocket`,
      });

      audioSocket.onopen = () => {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `Successfully established connection to restaurant specific audio websocket`,
        });

        store.dispatch(messagingActions.audioWSConnectionEstablished());

        store.dispatch(
          sendNetworkCallLogs({
            url: audioWebSocketURL,
            duration: audioWebSocketConnectionTimer.stop(),
            message: `restaurant specific audio websocket connection established successfully`,
            via: 'websocket',
          })
        );

        const payload: Partial<InfoTransmissionMessage> = {
          data: {
            type: 'METRIC',
            message: `Test audio websocket signal`,
          },
        };
        store.dispatch(messagingActions.sendInfo(payload as any));
      };

      let messages: any[] = [];
      let audioMessageSample: AudioFrameTransmissionMessage | null = null;
      audioSocket.onmessage = (event: any) => {
        const data = JSON.parse(event.data);
        if (data) {
          messages.push(data);
        }
      };

      setInterval(() => {
        if (messages.length) {
          const filteredMessages: any[] = [],
            nonAudioMessages: any[] = [];

          messages.forEach((message) => {
            if (
              !taskAssignedSessionId ||
              (taskAssignedSessionId &&
                message.session_id === taskAssignedSessionId)
            ) {
              filteredMessages.push(message);
              if (message.event !== EventTypes.audio) {
                nonAudioMessages.push(message);
              } else {
                if (!audioMessageSample) audioMessageSample = message;
              }
            }
          });

          const { taskRouter, sessionBoundary, ai } = store.getState();
          store.dispatch(
            messagingActions.messageReceived({
              messages: filteredMessages,
              taskRouter,
              sessionBoundarySessionId: sessionBoundary.sessionId,
              ai,
            })
          );
        }
        messages = [];
      }, 100);

      // Sampling audio event every 10 second
      setInterval(() => {
        if (audioMessageSample) {
          logger.log({
            restaurantCode: selectedRestaurantCode,
            message: `Process audio message via restaurant specific websocket`,
            moreInfo: JSON.stringify(audioMessageSample),
          });
          audioMessageSample = null;
        }
      }, 10000);

      audioSocket.onclose = (event: any) => {
        logger.log({
          restaurantCode: selectedRestaurantCode,
          message: `Restaurant specific audio websocket is closed unintentionally`,
          moreInfo: JSON.stringify(event, ['code', 'reason', 'wasClean']),
        });

        if (audioSocket?.readyState === audioSocket?.CLOSED) {
          audioWebSocketConnectionTimer.stop();
        }
        store.dispatch(messagingActions.audioWSConnectionLost());
        reconnect({ isAudioWS: true, maxRetries: 5 });
      };
    };

    if (messagingActions.startConnecting.match(action)) {
      eventConnect();
    }

    if (messagingActions.closeConnection.match(action)) {
      if (eventSocket) {
        eventSocket.onclose = () => {
          logger.log({
            restaurantCode: selectedRestaurantCode,
            message:
              'Successfully closed connection to restaurant specific websocket by close-connection action',
          });
        };
        eventSocket.close();
        eventSocket = undefined;
        eventWebSocketRetries = 1;
      }
    }

    if (messagingActions.startAudioWSConnecting.match(action)) {
      audioConnect();
    }

    if (messagingActions.closeAudioWSConnection.match(action)) {
      if (audioSocket) {
        audioSocket.onclose = () => {
          logger.log({
            restaurantCode: selectedRestaurantCode,
            message:
              'Successfully closed connection to restaurant specific audio websocket by close-connection action',
          });
        };
        audioSocket.close();
        audioSocket = undefined;
        audioWebSocketRetries = 1;
      }
    }

    if (isEventConnectionEstablished && isMatchMessagingAction(action)) {
      eventSocket?.send(JSON.stringify(action.payload));
    }

    next(action);
  };
};

export let infoSeqIdCounter = new Counter();

const messagingTransformMiddleware: Middleware = (store: RootStore) => {
  let textSeqIdCounter = 0;
  let endSessionSeqIdCounter = 0;
  let orderSeqIdCounter = 0;
  let loyaltySeqIdCounter = 0;
  let ttsRequestSeqIdCounter = 0;
  let errorSeqIdCounter = 0;
  let agentFirstActivitySeqIdCounter = 0;
  let agentFirstInterceptionSeqIdCounter = 0;
  let agentInterceptionSeqIdCounter = 0;
  let staffInterceptionSeqIdCounter = 0;
  let hitlSessionStartSeqIdCounter = 0;
  let hitlSessionEndSeqIdCounter = 0;

  let hitlEventConnectionSeqIdCounter = 0;
  let cancelOrderSeqIdCounter = 0;

  return (next) => (action) => {
    if (isMatchMessagingAction(action)) {
      const {
        sessionBoundary,
        user,
        messages,
        restaurant,
        taskRouter,
        menu,
        cachedMenu,
      } = store.getState() || {};
      const { email, username, id, firstName, lastName } =
        user?.userProfile || {};

      let payload = {
        ...action.payload,
        id: uuidv4(),
        session_id:
          taskRouter?.taskAssignedSessionId ||
          sessionBoundary?.sessionId ||
          sessionBoundary?.endedSessionId ||
          messages?.currentSessionId,
        ...(taskRouter.taskAssignedLaneId
          ? { lane_id: taskRouter.taskAssignedLaneId }
          : null),
        agent_type: AgentTypes.HITL,
        agent_id: agent.getAgentId(),
        timestamp: new Date().toISOString(),
        restaurant_code:
          restaurant.selectedRestaurantDetails?.restaurantCode ||
          DUMMY_RESTAURANT_CODE,
        // I would prefer not to expose this, see PRV-2631
        metadata: {
          agent_name: username,
          agent_id: id,
          agent_email: email,
          agent_first_name: firstName,
          agent_last_name: lastName,
        },
        environment: readEnvVariable('DEPLOY_ENV'),
      };

      switch (action.type) {
        case messagingActions.sendMessage.type:
          payload.event = EventTypes.text;
          payload.seq = textSeqIdCounter++;
          break;
        case messagingActions.sendEndSession.type:
          payload.event = EventTypes.endSession;
          payload.seq = endSessionSeqIdCounter++;
          break;
        case messagingActions.sendInfo.type:
          payload.event = EventTypes.info;
          payload.seq = infoSeqIdCounter.increment();
          break;
        case messagingActions.sendOrder.type:
          const prpMenuVersion = getMenuVersion({
            menu,
            restaurant,
            taskRouter,
            cachedMenu,
          });

          payload.event = EventTypes.order;
          payload.seq = orderSeqIdCounter++;
          payload.metadata = {
            ...payload.metadata,
            ...(prpMenuVersion
              ? { prp_menu_version: prpMenuVersion + '' }
              : null),
          };
          break;
        case messagingActions.sendAgentFirstActivity.type:
          payload.event = EventTypes.agentFirstActivity;
          payload.seq = agentFirstActivitySeqIdCounter++;
          break;
        case messagingActions.sendAgentFirstInterception.type:
          payload.event = EventTypes.agentFirstInterception;
          payload.seq = agentFirstInterceptionSeqIdCounter++;
          break;
        case messagingActions.sendAgentInterception.type:
          payload.event = EventTypes.agentInterception;
          payload.seq = agentInterceptionSeqIdCounter++;
          break;
        case messagingActions.sendStaffInterception.type:
          payload.event = EventTypes.staffIntervention;
          payload.seq = staffInterceptionSeqIdCounter++;
          break;
        case messagingActions.sendLoyalty.type:
          payload.event = EventTypes.loyalty;
          payload.seq = loyaltySeqIdCounter++;
          break;
        case messagingActions.sendTTSRequest.type:
          payload.event = EventTypes.TTSRequest;
          payload.seq = ttsRequestSeqIdCounter++;
          break;
        case messagingActions.sendError.type:
          payload.event = EventTypes.error;
          payload.seq = errorSeqIdCounter++;
          break;
        case messagingActions.sendHITLSessionStart.type:
          payload.event = EventTypes.hitlSessionStart;
          payload.seq = hitlSessionStartSeqIdCounter++;
          break;
        case messagingActions.sendHITLSessionEnd.type:
          payload.event = EventTypes.hitlSessionEnd;
          payload.seq = hitlSessionEndSeqIdCounter++;
          break;
        case messagingActions.sendHITLEventConnection.type:
          payload.event = EventTypes.hitlEventConnection;
          payload.seq = hitlEventConnectionSeqIdCounter++;
          break;
        case messagingActions.sendCancelOrder.type:
          payload.event = EventTypes.cancelOrder;
          payload.seq = cancelOrderSeqIdCounter++;
          break;
        default:
          break;
      }
      if (!('data' in payload)) {
        payload.data = {}; // Sending empty data field to be consistent with the high level schema for all other events
      }
      action.payload = payload;
      logger.log({
        restaurantCode:
          store.getState().restaurant?.selectedRestaurantDetails.restaurantCode,
        message: `Sending the message with event ${payload.event} via restaurant specific websocket`,
        moreInfo: JSON.stringify(payload),
      });
    }

    next(action);
  };
};

export { messagingTransformMiddleware, socketMiddleware };
