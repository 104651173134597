import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { TranscriptTypes } from '../../constants/enums';
import { TextFrameTransmissionMessage } from '../../reducers/messagingSlice';
import { LOWER_CASE_O, OH } from '../../utils/constants';
import { TranscriptMessage } from '../TranscriptMessage';

interface OwnProps {
  messageList: TextFrameTransmissionMessage[];
}

const useStyles = makeStyles(({}) => ({
  bottomRefClass: {
    paddingBottom: '10px',
  },
}));

export function MessageContainer({ messageList }: OwnProps) {
  const messagesBottomRef = useRef<HTMLDivElement>(null);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const { bottomRefClass } = useStyles();

  const lastMessage =
    messageList.length > 0 ? messageList[messageList.length - 1] : undefined;

  const scrollToBottom = () => {
    if (
      messagesBottomRef &&
      messagesBottomRef.current &&
      listInnerRef &&
      listInnerRef.current
    ) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        messagesBottomRef.current.scrollIntoView?.();
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [lastMessage]);

  const renderMessage = (message: TextFrameTransmissionMessage) => {
    let payload = message.data?.payload;
    if (message.data && message.agent_type == 'ORDERBOARD' && payload) {
      if (message.data.status !== 'TTS') {
        return (
          <TranscriptMessage
            key={message.id}
            text={payload}
            type={TranscriptTypes.CX}
          />
        );
      }

      const regularExpr = /\d+\sOh\s\d+/;
      if (regularExpr.exec(payload)) {
        payload = payload.replace(OH, LOWER_CASE_O); // Display 'Oh' in the total tts visually as 'O'
      }
      return (
        <TranscriptMessage
          key={message.id}
          text={payload}
          type={TranscriptTypes.AI}
        />
      );
    }
    return <></>;
  };

  return (
    <div data-testid="messageContainer" ref={listInnerRef}>
      {messageList.map((message) => renderMessage(message))}
      <div
        data-testid="transcriptBottomRef"
        ref={messagesBottomRef}
        className={bottomRefClass}
      />
    </div>
  );
}
