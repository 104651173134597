import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { PagerDutyUploadMutation } from '../../../generated-interfaces/graphql';
import { selectMenuVersion } from '../../../reducers/menuSlice';
import {
  selectRestaurant,
  selectStage,
} from '../../../reducers/restaurantSlice';
import {
  createFileFromText,
  createImageFromCanvas,
  MimeType,
} from '../../../utils/file';
import logger from '../../../utils/logger';
import {
  createPagerDutyIncident,
  getGraphQLClient,
  getRestaurantDiagnostic,
} from '../../../utils/network';
import { selectPagerdutyIntegrationKey } from '../config/config.selector';
import {
  CheckStatus,
  IntegratedSystems,
  IntegratedSystemsStatus,
  RESET_TIMEOUT,
  SLICE_NAME,
} from './healthStatusCheck.constant';
import { HealthStatusCheckContent } from './healthStatusCheck.props';
import { selectHealthStatusCheckState } from './healthStatusCheck.selector';
import { initialState } from './healthStatusCheck.utils';

export const checkHealthStatus = createAsyncThunk(
  'healthStatusCheck/checkStatus',
  (_: void, { getState, dispatch }) => {
    const {
      restaurant: {
        selectedRestaurantDetails: { restaurantCode },
      },
      config: { RESTAURANT_DIAGNOSTIC_API },
    } = getState();
    const handleRestaurantDiagnosticResponse = (response: any) => {
      const unhealthyServices = Object.keys(response)
        .filter(
          (key: any) =>
            [
              IntegratedSystems.HITL,
              IntegratedSystems.event,
              IntegratedSystems.poslink,
              IntegratedSystems.posmon,
              IntegratedSystems.configuration,
            ].includes(key) &&
            response[key].status === IntegratedSystemsStatus.unhealthy
        )
        .map((key: any) => ({
          name: key.charAt(0).toUpperCase() + key.slice(1).replace('S', ' S'),
          status: response[key].status,
        }));
      if (unhealthyServices.length) {
        dispatch(setIsHealthy(false));
        dispatch(
          setHealthStatusCheckContent({
            heading: `"${restaurantCode}" is experiencing issues`,
            body: unhealthyServices,
          })
        );
      } else {
        dispatch(setIsHealthy(true));
        dispatch(
          setHealthStatusCheckContent({
            heading: `"${restaurantCode}" is healthy`,
            body: 'All systems ok',
          })
        );
        setTimeout(() => {
          dispatch(resetHealthStatusState());
        }, RESET_TIMEOUT);
      }

      dispatch(
        setGeneratedTime(
          `This report was generated ${moment().format(
            'MMM Do YYYY [at] h:mm a'
          )}`
        )
      );
      dispatch(setCheckStatus(CheckStatus.completed));
    };

    if (restaurantCode) {
      dispatch(setCheckStatus(CheckStatus.pending));
      getRestaurantDiagnostic(
        RESTAURANT_DIAGNOSTIC_API,
        restaurantCode,
        handleRestaurantDiagnosticResponse
      );
    }
  }
);

export const reportLogsToPagerDuty = createAsyncThunk(
  `${SLICE_NAME}/reportLogsToPagerDuty`,
  async ({ restaurantCode }: { restaurantCode: string }, { getState }) => {
    const canvas = await html2canvas(document.body);

    const timestamp = Date.now();

    const screenshot = await createImageFromCanvas(canvas, {
      mimeType: MimeType.png,
      name: `hitl_web_ss_${timestamp}`,
    });

    const logs = createFileFromText(logger.getAllLogs().join('\n'), {
      name: `hitl_web_logs_${timestamp}`,
      mimeType: MimeType.plain,
    });

    const sdk = getGraphQLClient(getState().config.PRP_API);

    const [{ pagerDutyUpload: screenshotURL }, { pagerDutyUpload: logsURL }] =
      (await Promise.all([
        sdk.pagerDutyUpload({
          file: screenshot,
          fileName: null,
        }),
        sdk.pagerDutyUpload({
          file: logs,
          fileName: null,
        }),
      ])) as unknown as PagerDutyUploadMutation[];

    const { generatedTime, healthStatusCheckContent } =
      selectHealthStatusCheckState(getState());
    const integrationKey = selectPagerdutyIntegrationKey(getState());

    await createPagerDutyIncident({
      summary: healthStatusCheckContent.heading,
      integrationKey,
      links: [{ href: logsURL, text: 'Logs' }],
      images: [{ alt: 'Screenshot', src: screenshotURL }],
      details: {
        healthStatus: healthStatusCheckContent.body,
        healthStatusGeneratedAt: generatedTime,
        Impacted_Locations: restaurantCode,
      },
    });
  }
);

export const healthStatusCheckSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setGeneratedTime: (state, action: PayloadAction<string>) => {
      state.generatedTime = action.payload;
    },
    setHealthStatusCheckContent: (
      state,
      action: PayloadAction<HealthStatusCheckContent>
    ) => {
      state.healthStatusCheckContent = action.payload;
    },
    setCheckStatus: (state, action) => {
      state.checkStatus = action.payload;
    },
    setIsHealthy: (state, action) => {
      state.isHealthy = action.payload;
    },
    resetHealthStatusState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        selectRestaurant.fulfilled,
        selectStage.fulfilled,
        selectMenuVersion.fulfilled
      ),
      () => initialState
    );
  },
});

const {
  setGeneratedTime,
  setHealthStatusCheckContent,
  setCheckStatus,
  setIsHealthy,
  resetHealthStatusState,
} = healthStatusCheckSlice.actions;

export {
  resetHealthStatusState,
  setCheckStatus,
  setGeneratedTime,
  setHealthStatusCheckContent,
  setIsHealthy,
};
export default healthStatusCheckSlice.reducer;
