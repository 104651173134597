import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import useTaskRouterHandlers from '../../hooks/taskRouterHandlers/taskRouterHandlers.hooks';
import { cartActions } from '../../reducers/cartSlice';
import { resetDialogSelectedItem } from '../../reducers/dialogSlice';
import {
  EndSessionTransmissionMessage,
  RestaurantStaffInterventionStatus,
  messagingActions,
  resetHypothesisFrame,
  sendCancelOrder,
  ICancelOrderTransmissionMessage,
  AgentInterceptionType,
} from '../../reducers/messagingSlice';
import { orderActions, sendOrder } from '../../reducers/orderSlice';
import { selectIsAIAutoMode } from '../../redux/features/ai/ai.selector';
import { selectFinishOrderTimer } from '../../redux/features/config/config.selector';
import {
  selectFinalOrderSubmitted,
  selectIsPostCarExitTimerActive,
  selectOrderId,
} from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import {
  endSession,
  resetOrderMessageOrderId,
  setFinalOrderSubmitted,
} from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectValidCartItems } from '../../selectors/cart';
import {
  selectIsStaffIntervention,
  selectRestaurantStaffIntervention,
} from '../../selectors/message';
import { selectActiveRestaurantCode } from '../../selectors/restaurant';
import { OrderActionArgs } from '../../types';
import { activeCartSelector, isValidCartSelector } from '../../utils/cart';
import Colors from '../../utils/color';
import { END_SESSION_REASON } from '../../utils/constants';
import logger from '../../utils/logger';
import DwindlingTimer from '../DwindlingTimer';
import { EventSources } from '../../constants/event';
import { v4 as uuidv4 } from 'uuid';
import { selectLastHITLOrderId } from '../../selectors/order';
import useAgentInterception from '../../hooks/agentInterception/useAgentInterception.hooks';
import { selectIsKillSwitchClicked } from '../../redux/features/taskRouter/taskRouter.selector';
import { SessionEndReasons } from '../../redux/features/sessionBoundary/sessionBoundary.constants';

const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    height: '64px',
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
  },
  finishButton: {
    position: 'relative',
    '&:hover >div': {
      backgroundColor: `${Colors.vidaLoca} !important`,
    },
  },
  timerButton: {
    position: 'absolute',
    top: '-20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    width: '80px',
    height: '36px',
    backgroundColor: Colors.abbey,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${Colors.vidaLoca} !important`,
    },
  },
  actionButton: {
    flex: 1,
    fontWeight: 'bold !important',
  },
  addBlackBackgroundColor: {
    backgroundColor: Colors.black,
  },
  customizeDisabledButton: {
    backgroundColor: `${Colors.alto2} !important`,
  },
  customizeDisabledTimerButton: {
    backgroundColor: `${Colors.alto2} !important`,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
  },
}));

export const OrderActions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const maxTimerSeconds = useAppSelector(selectFinishOrderTimer);
  const isPostCarExitTimerActivated = useAppSelector(
    selectIsPostCarExitTimerActive
  );

  const cartActive = useSelector(activeCartSelector);
  const cartValid = useSelector(isValidCartSelector);
  const isAIAutoMode = useSelector(selectIsAIAutoMode);

  const validCartItems = useSelector(selectValidCartItems);
  const restaurantCode = useSelector(selectActiveRestaurantCode);
  const orderSessionId = useSelector(
    (state: RootState) => state.order.currentSessionId
  );
  const messageSessionId = useSelector(
    (state: RootState) => state.messages.currentSessionId
  );
  const isStaffIntervention = useSelector(selectIsStaffIntervention);
  const restaurantStaffIntervention = useSelector(
    selectRestaurantStaffIntervention
  );
  const isRestaurantStaffInterventionClicked =
    restaurantStaffIntervention !== RestaurantStaffInterventionStatus.initial;
  const { sendTaskStatus, isCurrentTaskFromTaskRouter, currentTaskStatus } =
    useTaskRouterHandlers();

  const isFinalOrderSubmitted = useSelector(selectFinalOrderSubmitted);

  const aiOrderId = useSelector(selectOrderId);
  const lastHITLOrderId = useSelector(selectLastHITLOrderId);
  const lastOrderId = aiOrderId || lastHITLOrderId;

  const { sendAgentInterception } = useAgentInterception();

  const resetStatesOnOrderAction = () => {
    dispatch(resetDialogSelectedItem());
    dispatch(cartActions.clearCart());
    dispatch(resetHypothesisFrame());
    logger.log(
      'Reset session boundary orderMessageOrderId in resetStatesOnOrderAction'
    );
    dispatch(resetOrderMessageOrderId()); // Resetting AI order ID, as currently AI supports only single order
  };
  const isKillSwitchClicked = useAppSelector(selectIsKillSwitchClicked);

  useEffect(() => {
    if (isRestaurantStaffInterventionClicked) {
      logger.log(
        'Call cancelOrder when the restaurant staff button is clicked in OrderActions'
      );
      //When the "Restaurant Staff" button is clicked, a modal to be shown to select the reason
      cancelOrder({});
    }
  }, [isRestaurantStaffInterventionClicked]);

  useEffect(() => {
    if (isFinalOrderSubmitted) {
      //The order is submitted by AI. Not call finishOrder() because some of the actions are not needed
      resetStatesOnOrderAction();
      if (isCurrentTaskFromTaskRouter) {
        const status =
          currentTaskStatus === TaskStatuses.canceled
            ? TaskStatuses.canceled
            : TaskStatuses.completed;
        sendTaskStatus(status, { isFinishOrder: true });
      }
      if (isAIAutoMode) {
        logger.log(
          'Call finishOrderResetValues when final order is submitted by AI in AI mode in OrderActions'
        );
        // Resetting order state after AI is active and Finish order button is clicked
        dispatch(orderActions.finishOrderResetValues());
      }
    }
  }, [isFinalOrderSubmitted]);

  useEffect(() => {
    //When 'staff_intervention' event (or) info event with message 'StaffIntervention' is received via websocket
    if (isStaffIntervention) {
      if (isCurrentTaskFromTaskRouter) {
        //When "Restaurant Staff" button is clicked, StaffIntervention event is also being received.
        //To make sure that cancel order is not called twice
        if (!isRestaurantStaffInterventionClicked) {
          logger.log(
            'Call cancelOrder on staff intervention event in OrderActions'
          );
          cancelOrder({
            isStaffIntervention: true,
          });
        }
      } else {
        logger.log(
          'Call cancelOrder on staff intervention event but not on a task in OrderActions'
        );
        cancelOrder({});
      }
    }
  }, [isStaffIntervention]);

  useEffect(() => {
    if (isKillSwitchClicked) {
      logger.log('Call autoSubmitHandler when kill switch is clicked');
      autoSubmitHandler();
      dispatch(endSession(SessionEndReasons.killSwitchClicked));
    }
  }, [isKillSwitchClicked]);

  const sendEndSession = (reason: string) => {
    if (restaurantCode) {
      const payload: Partial<EndSessionTransmissionMessage> = {
        data: {
          restaurant_code: restaurantCode,
          session_id: orderSessionId || messageSessionId,
        }, // still end the session if there is no order session yet
        metadata: reason,
      };
      dispatch(messagingActions.sendEndSession(payload as any));
    }
  };

  const cancelOrder = (args: OrderActionArgs) => {
    const { isCancelOrder, isAutoSubmitted } = args;
    logger.log({
      message: 'Call cancelOrder',
      moreInfo: JSON.stringify(args),
    });
    if (!isAutoSubmitted) {
      sendAgentInterception({
        data: {
          type: AgentInterceptionType.CANCEL_ORDER,
        },
      });
    }
    if (isCancelOrder && lastOrderId) {
      const payload: Partial<ICancelOrderTransmissionMessage> = {
        data: {
          check_id: '-1',
          source: EventSources.prestoVoice,
          request_id: uuidv4(),
          store_id: restaurantCode,
          order_id: lastOrderId,
        },
      };
      dispatch(sendCancelOrder(payload as any));
    }
    sendEndSession(END_SESSION_REASON.CANCEL_ORDER);
    resetStatesOnOrderAction();
    logger.log('Call finishOrderResetValues in cancelOrder');
    dispatch(orderActions.finishOrderResetValues());
    dispatch(messagingActions.clearMessages());
    dispatch(orderActions.resetCurrentTransactionId());
    sendTaskStatus(TaskStatuses.canceled, args);
    dispatch(setFinalOrderSubmitted(true));
    logger.log('Reset session boundary orderMessageOrderId in cancelOrder');
    dispatch(resetOrderMessageOrderId());
  };

  const finishOrder = (args: OrderActionArgs) => {
    const { isAutoSubmitted } = args;
    logger.log('Call finishOrder');
    if (!isAutoSubmitted) {
      sendAgentInterception({
        data: {
          type: AgentInterceptionType.FINISH_ORDER,
        },
      });
    }
    sendEndSession(END_SESSION_REASON.FINISH_ORDER);
    dispatch(sendOrder({ isFinal: true }));
    resetStatesOnOrderAction();
    dispatch(messagingActions.clearMessages());
    sendTaskStatus(TaskStatuses.completed, { isFinishOrder: true });
    dispatch(setFinalOrderSubmitted(true));
    if (isAIAutoMode) {
      logger.log('Call finishOrderResetValues in finishOrder with AI mode on');
      // Resetting order state after AI is active and Finish order button is clicked
      dispatch(orderActions.finishOrderResetValues());
    }
  };

  const handleFinishOrderClick = () => {
    logger.log('Call handleFinishOrderClick');
    finishOrder({});
  };

  const handleCancelOrderClick = () => {
    logger.log('Call cancelOrder in handleCancelOrderClick');
    cancelOrder({ isCancelOrder: true });
  };

  const autoSubmitHandler = () => {
    if (Object.values(validCartItems).length) {
      logger.log('Auto submitting the order');
      finishOrder({ isAutoSubmitted: true });
    } else if (lastOrderId) {
      logger.log('Auto canceling the order');
      cancelOrder({ isCancelOrder: true, isAutoSubmitted: true });
    }
  };

  return (
    <div
      id="orderActions"
      className={`${classes.buttonWrapper} ${
        cartActive ? classes.addBlackBackgroundColor : ''
      }`}
    >
      <Button
        onClick={handleCancelOrderClick}
        variant="contained"
        disabled={!restaurantCode}
        color="error"
        className={classes.actionButton}
      >
        Cancel Order
      </Button>
      <Button
        onClick={handleFinishOrderClick}
        variant="contained"
        color="success"
        disabled={!cartActive || !cartValid}
        className={`${classes.finishButton} ${classes.actionButton} ${
          !cartValid ? classes.customizeDisabledButton : ''
        }`}
      >
        Finish Order
        {isPostCarExitTimerActivated && (
          <div
            className={`${classes.timerButton} ${
              !cartValid || !cartActive
                ? classes.customizeDisabledTimerButton
                : ''
            }`}
          >
            <DwindlingTimer
              maxTime={maxTimerSeconds}
              timeoutCallback={autoSubmitHandler}
            />
          </div>
        )}
      </Button>
    </div>
  );
};
