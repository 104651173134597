import { CartItem } from './cart';
import { ErrorResponse } from './types';
import { IEntityCartItem } from '../reducers/cartSlice.props';

export const getErrorMessages = (errorResponse: ErrorResponse) => {
  return errorResponse.message;
};

export const getOrderErrorMessage = (
  error: string,
  cartItem: CartItem | IEntityCartItem
) => {
  let result = 'Sorry, ';
  if (
    error.includes(cartItem.name) ||
    error === 'An Unkown Error Has Occurred'
  ) {
    result += cartItem.name;
  } else if ('childModifierGroups' in cartItem) {
    Object.values(cartItem.childModifierGroups).forEach((childModGroup) => {
      Object.values(childModGroup.selectedItems).forEach((selectedItem) => {
        if (error.includes(selectedItem.name)) {
          result += selectedItem.name + ' ';
        }
      });
    });
    result += 'for ' + cartItem.name;
  } else if ('children' in cartItem) {
    Object.values(cartItem.children).forEach((child) => {
      if (error.includes(child.name)) {
        result += child.name + ' ';
      }
    });
    result += 'for ' + cartItem.name;
  }
  result += ' is not available right now. Would you like something else?';
  return result;
};
