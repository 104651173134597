import { Typography } from '@mui/material';
import { FC, default as React, useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectTaskRouterNotificationSound } from '../../redux/features/config/config.selector';
import { selectAssignedTaskId } from '../../redux/features/taskRouter/taskRouter.selector';
import { TASK_ASSIGN_AUDIO } from '../../sounds/task_assign_audio';

const TaskRouterAgentAssignment: FC = () => {
  const canPlayNotificationSound = useAppSelector(
    selectTaskRouterNotificationSound
  );
  const taskAssignedId = useAppSelector(selectAssignedTaskId);

  useEffect(() => {
    if (canPlayNotificationSound && taskAssignedId) {
      TASK_ASSIGN_AUDIO?.play();
    }
  }, [taskAssignedId, canPlayNotificationSound]);

  return (
    <div data-testid="tr-agent-assignment">
      <Typography variant="h4" gutterBottom>
        Get ready
      </Typography>
      <Typography>New tasks will be offered to you shortly</Typography>
    </div>
  );
};

export default TaskRouterAgentAssignment;
