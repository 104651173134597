import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const selectSessionBoundary = (state: RootState) => state.sessionBoundary;
const selectSessionStatus = createSelector(
  selectSessionBoundary,
  (state) => state.sessionStatus
);
const selectSessionStartReason = createSelector(
  selectSessionBoundary,
  (state) => state.sessionStartReason
);
const selectSessionEndReason = createSelector(
  selectSessionBoundary,
  (state) => state.sessionEndReason
);

const selectSessionId = createSelector(
  selectSessionBoundary,
  (state) => state.sessionId
);

const selectFinalOrderSubmitted = createSelector(
  selectSessionBoundary,
  (state) => state.finalOrderSubmitted
);

const selectOrderMessageData = createSelector(
  selectSessionBoundary,
  (state) => state.orderMessageData
);

const selectIsPostCarExitTimerActive = createSelector(
  selectSessionBoundary,
  (state) => state.isPostCarExitTimerActivated
);

const selectIsSessionTimeoutDialogOpen = createSelector(
  selectSessionBoundary,
  (state) => state.isSessionTimeoutDialogOpen
);

const selectOrderId = createSelector(
  selectSessionBoundary,
  (state) => state.orderMessageOrderId
);

const selectShowOrderSubmissionAlert = createSelector(
  selectSessionBoundary,
  (state) => state.showOrderSubmissionAlert
);

const selectEndedSessionId = createSelector(
  selectSessionBoundary,
  (state) => state.endedSessionId
);

export {
  selectFinalOrderSubmitted,
  selectIsPostCarExitTimerActive,
  selectIsSessionTimeoutDialogOpen,
  selectOrderId,
  selectOrderMessageData,
  selectSessionEndReason,
  selectSessionId,
  selectSessionStartReason,
  selectSessionStatus,
  selectShowOrderSubmissionAlert,
  selectEndedSessionId,
};
