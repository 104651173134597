import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectRestaurant = (state: RootState) => state.restaurant;

const selectActiveRestaurantDetails = createSelector(
  selectRestaurant,
  (restaurant) => restaurant.selectedRestaurantDetails
);
const selectActiveRestaurantCode = createSelector(
  selectActiveRestaurantDetails,
  (restaurantDetails) => restaurantDetails?.restaurantCode
);
const selectActiveRestaurantTimezone = createSelector(
  selectActiveRestaurantDetails,
  (restaurantDetails) => restaurantDetails?.timezone
);
const selectActiveRestaurantIsUnsupervisedAI = createSelector(
  selectActiveRestaurantDetails,
  (restaurantDetails) => restaurantDetails?.isUnsupervisedAi
);

const selectRestaurantsByUserRole = createSelector(
  selectRestaurant,
  (restaurant) => restaurant.restaurantsByUserRole
);
const selectRestaurantsById = createSelector(
  selectRestaurant,
  (restaurant) => restaurant.restaurantsById
);

const selectRestaurantAccessLevels = (state: RootState) =>
  state.restaurant.restaurantAccessLevels;

const selectedStageSelector = (state: RootState) =>
  state.restaurant.selectedStage;
const selectAIIndicator = (state: RootState) => state.restaurant.isAIEnabled;

export {
  selectAIIndicator,
  selectActiveRestaurantCode,
  selectActiveRestaurantDetails,
  selectActiveRestaurantIsUnsupervisedAI,
  selectActiveRestaurantTimezone,
  selectRestaurant,
  selectRestaurantAccessLevels,
  selectRestaurantsById,
  selectRestaurantsByUserRole,
  selectedStageSelector,
};
