const Colors = {
  white: '#FFFFFF',
  red: '#FF0000',
  daintree: '#02263d',
  gold: '#ffd500',
  amber: '#ffc107',
  webOrange: '#FEA300',
  chartreuseYellow: '#D9FD04',
  cyan: '#1BDAFF',
  alto: '#E0E0E0',
  wildSand: '#F5F5F5',
  silverChalice: '#9E9E9E',
  black: '#000000',
  mineShaft: '#212121',
  Thunderbird: '#BE3019',
  porcelain: '#F1F3F4',
  gallery: '#EBEBEB',
  alto2: '#D3D3D3',
  abbey: '#7ed321',
  torchRed: '#FF0048',
  internationalOrange: '#FF5A00',
  welcome: '#08a045',
  sayAgain: '#9A1D85',
  readBack: '#006ba6',
  total: '#FF6C0A',
  dim: '#979dac',
  brightGreen: '#70e000',
  aiItemIndicator: '#fffdeb',
  redRibbon: '#f30252',
  bunker: '#0d151c',
  bittersweet: '#FC7373',
  vidaLoca: '#589317',
  guardsmanRed: '#CC0001',
  beeswax: '#FDFAC4',
  apricot: '#FFC8BA',
  spindle: '#B9D4ED',
  cerulean: '#06bee1',
  bluishCyan: '#0797ea',
  //   --light-pink: #ffadadff;
  // --deep-champagne: #ffd6a5ff;
  // --lemon-yellow-crayola: #fdffb6ff;
  // --tea-green: #caffbfff;
  // --electric-blue: #9bf6ffff;
  // --baby-blue-eyes: #a0c4ffff;
  // --maximum-blue-purple: #bdb2ffff;
  // --mauve: #ffc6ffff;
  // --baby-powder: #fffffcff;

  // Color Palette for Items
  froly: '#ee6677',
  forestGreen: '#228833',
  sanMarino: '#4477AA',
  turmeric: '#CCBB44',
  skyBlue: '#66ccee',
  royalHeath: '#AA3377',
  texasRose: '#ffb74d',
  emerald: '#30d158',
};

export const sequentialItemColors = [
  Colors.froly,
  Colors.forestGreen,
  Colors.sanMarino,
  Colors.turmeric,
  Colors.skyBlue,
  Colors.royalHeath,
];

export const convertUuidToColorNumber = (id: string) => {
  if (!id) return 0;
  const lastStr = id.split('-').pop();
  return Number.parseInt(lastStr || '0', 16);
};

export default Colors;
