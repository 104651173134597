import React, { MouseEvent } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    Theme, IconButton, Select, MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {IEntityCartItem} from "../../reducers/cartSlice.props";
import HypothesisCartItemModGroups from "./HypothesisItemModGroups";
import Colors from '../../utils/color';
import OrderProgressBar from "./OrderProgressBar";
import {OrderStatus} from "../../reducers/orderSlice.constants";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

interface Props {
    hypothesisCartItem: IEntityCartItem;
    handleHypothesisItemRemove: (id: string) => void;
    status: OrderStatus;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
    container: {
        marginTop: spacing(2),
        cursor: 'pointer',
        backgroundColor: `${Colors.gallery} !important`
    },
    content: {
        paddingBottom: `${spacing(0.5)} !important`,
    },
    actionContainer: {
        '& .MuiButtonBase-root.MuiButton-root': {
            textTransform: 'none',
        },
        textAlign: 'right',
    },
    buttonStyle: {
        fontWeight: 'bold !important',
    },
    loyaltyStatusFontStyle: {
        fontSize: `${spacing(1.5)} !important`,
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        '& span': {
            cursor: 'pointer',
        },
    },
    quantity: {
        textAlign: 'center',
        display: 'inline-block',
        minWidth: '40px !important',
        paddingRight: '2px !important',
        paddingLeft: '0px !important',
    },
    mainItemname: {
        '& h6': {
            lineHeight: '1.57 !important',
            fontSize: `${spacing(1.5)} !important`,
            fontWeight: 'bold !important',
        },
        paddingBottom: spacing(1),
    },
}));

export default function HypothesisItemReview({ hypothesisCartItem, handleHypothesisItemRemove, status }: Props) {
    const {
        container,
        content,
        buttonStyle,
        actionContainer,
        actionsWrapper,
        quantity,
        mainItemname,
    } = useStyles();

    const removeHypothesisCartItem = (e: MouseEvent) => {
        e.stopPropagation();
        handleHypothesisItemRemove(hypothesisCartItem.cartItemId.toString())
    };

    return (
        <Card elevation={3} className={container}>
            <CardContent className={content}>
                <div className={mainItemname}>
                    <Typography variant="subtitle2">
                        {hypothesisCartItem.name}
                    </Typography>
                    <Typography variant="subtitle2">
                        (Not found in HITL)
                    </Typography>
                </div>
                {
                    hypothesisCartItem.children.length && <HypothesisCartItemModGroups hypothesisCartItemModGroups={hypothesisCartItem.children} />
                }
                <div className={actionsWrapper}>
                    <div>
                        <IconButton size="small" disabled={true}>
                            <RemoveIcon />
                        </IconButton>
                        <span className={quantity}>{hypothesisCartItem.quantity}</span>
                        <IconButton size="small" disabled={true}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <Box className={actionContainer}>
                        <Button
                            variant="text"
                            color="error"
                            size="small"
                            className={buttonStyle}
                            onClick={removeHypothesisCartItem}
                        >
                            Remove
                        </Button>
                    </Box>
                </div>
            </CardContent>
            <OrderProgressBar status={status} cartItem={hypothesisCartItem}/>
        </Card>
    );
}
