export enum TaskStatuses {
  none = 'none',
  offered = 'task_offered',
  offeredAck = 'task_offered_ack',
  accepted = 'task_accepted',
  rejected = 'task_rejected',
  invalid = 'task_invalid',
  /** This invalidAck indicates when agent is navigated back to TR lobby and is ready to process the next order */
  invalidAck = 'task_invalid_ack',
  assigned = 'task_assigned',
  assignedAck = 'task_assigned_ack',
  canceled = 'task_canceled',
  canceledAck = 'task_canceled_ack',
  completed = 'task_completed',
  completeAck = 'task_complete_ack',
  timeout = 'task_timeout_by_agent',
  exception = 'task_exception',
  loaded = 'task_loaded',
  loadedAck = 'task_loaded_ack',
  loadFail = 'task_load_failed',
  intervened = 'task_intervened',
  agentInterception = 'task_agent_interception',
  /** Logout agent due to inactivity indicated via agentInactiveForceLogout */
  agentInactiveForceLogout = 'agent_inactive_force_logout',
  /** whenever AI tasks are being assigned to non-preferred agents (or) non-AI tasks being assigned to preferred agents */
  info = 'info',
}

export const TaskStatusesInRestaurantPage = [
  TaskStatuses.canceled,
  TaskStatuses.canceledAck,
  TaskStatuses.completed,
  TaskStatuses.loaded,
  TaskStatuses.loadFail,
  TaskStatuses.agentInterception,
  TaskStatuses.intervened,
];

export enum AgentStatuses {
  available = 'agent_available',
  unavailable = 'agent_unavailable',
  training = 'agent_training',
}

export enum AgentEvents {
  login = 'agent_login',
  loginAck = 'agent_login_ack',
  logout = 'agent_logout',
  active = 'agent_active',
  activeAck = 'agent_active_ack',
  assigned = 'agent_assigned',
  available = 'agent_available',
  availableAck = 'agent_available_ack',
  unavailable = 'agent_unavailable',
  unavailableAck = 'agent_unavailable_ack',
  training = 'agent_training',
  trainingAck = 'agent_training_ack',

  //These events are used for representing heartbeat
  statusAvailable = 'agent_status_available',
  statusUnavailable = 'agent_status_unavailable',
  statusTraining = 'agent_status_training',
  statusAssigned = 'agent_status_assigned',
  statusActive = 'agent_status_active',
}
