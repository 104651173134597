import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { useInterval } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  TASKROUTER_RECONNECT_ATTEMPTS as reconnectAttempts,
  TASKROUTER_RECONNECT_INTERVAL as reconnectInterval,
  TASKROUTER_WSLINK,
} from '../../constants';
import AUTO_NAVIGATION_ALERT from '../../constants/autoNavigationAlert';
import { MenuStages, Routes } from '../../constants/enums';
import { AgentTypes } from '../../constants/event';
import {
  messagingActions,
  setStaffIntervention,
} from '../../reducers/messagingSlice';
import {
  selectConfig,
  selectTRAgentAutoAssignment,
} from '../../redux/features/config/config.selector';
import {
  startLoading,
  stopLoading,
  updateFetchingTaskStatus,
} from '../../redux/features/loading/loading.slice';
import {
  SessionEndReasons,
  SessionStatus,
} from '../../redux/features/sessionBoundary/sessionBoundary.constants';
import { selectSessionStatus } from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import {
  endSession,
  resetSessionBoundaryData,
} from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import {
  AgentEvents,
  AgentStatuses,
  TaskStatuses,
} from '../../redux/features/taskRouter/taskRouter.constants';
import { ITask } from '../../redux/features/taskRouter/taskRouter.props';
import {
  selectAgentInSession,
  selectAgentStatus,
  selectAssignedTaskId,
  selectCurrentTask,
  selectTasksById,
  selectTaskStatusChanged,
} from '../../redux/features/taskRouter/taskRouter.selector';
import {
  addNewTask,
  resetTaskRouterData,
  setAgentStatus,
  setNavigationViaTaskRouter,
  updateTaskStatus,
} from '../../redux/features/taskRouter/taskRouter.slice';
import { selectRestaurantAccessLevels } from '../../selectors/restaurant';
import { selectUserProfile } from '../../selectors/user';
import { UserDetails } from '../../types';
import { RolePermissions } from '../../types/restaurant';
import {
  getTaskRouterInfoFromLS,
  saveTaskRouterInfoInLS,
  updateTaskRouterInfoInLS,
} from '../../utils/local-storage';
import logger from '../../utils/logger';
import { getWebsocketUrl } from '../../utils/network';
import useTaskRouterHandlers from '../taskRouterHandlers/taskRouterHandlers.hooks';
import useTaskRouterWSActions from '../taskRouterWSActions/useTaskRouterWSActions.hooks';
import { getAgentInactivityFeatureFlag } from '../../utils/session-storage';
import { getAuthRefactorFeatureFlag } from '../../utils/session-storage';

let LAST_GHOST_CAR_ALERT = { task_id: '', session_id: '' };

const useTaskRouterWS = ({ logout }: { logout: Function }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();

  const { navigateToTaskRouterPage } = useTaskRouterHandlers();

  const config = useAppSelector(selectConfig);
  const agentStatus = useAppSelector(selectAgentStatus);
  const tasksById = useAppSelector(selectTasksById);
  const taskStatusChanged = useAppSelector(selectTaskStatusChanged);
  const userDetails = useAppSelector(selectUserProfile);
  const assignedTaskId = useAppSelector(selectAssignedTaskId);
  const restaurantAccessLevels = useAppSelector(selectRestaurantAccessLevels);
  const sessionStatus = useAppSelector(selectSessionStatus);
  const isTaskAssignmentMode = useAppSelector(selectTRAgentAutoAssignment);
  const isAgentOnTRPage = location.pathname.indexOf(Routes.taskRouter) >= 0;
  const currentTaskId = useAppSelector(selectCurrentTask);
  const isAgentInSession = useAppSelector(selectAgentInSession);

  const trWSLink = `${TASKROUTER_WSLINK}${
    isTaskAssignmentMode && userDetails?.id ? `_${userDetails.id}` : ''
  }`;

  const agentInactivityFeatureFlag = getAgentInactivityFeatureFlag();

  let newReconnectInterval = agentInactivityFeatureFlag
    ? reconnectInterval / 10
    : reconnectInterval;
  let newReconnectAttempts = agentInactivityFeatureFlag
    ? reconnectAttempts * 2
    : reconnectAttempts;

  //Open the web socket for the task router
  const { sendMessage, lastMessage } = useWebSocket(
    getWebsocketUrl(config, trWSLink, MenuStages.LIVE),
    {
      ...useTaskRouterWSActions(),
      reconnectInterval: newReconnectInterval,
      reconnectAttempts: newReconnectAttempts,
      retryOnError: true,
      share: true,
    }
  );

  useEffect(() => {
    //Process the messages received in the task router web socket connection only when agent is in task router page (or) if auto agent assignment mode is ON
    if (lastMessage !== null && (isTaskAssignmentMode || isAgentOnTRPage)) {
      processTaskRouterMessage();
    }
  }, [lastMessage]);

  useEffect(() => {
    //When a task is assigned to the current logged in user and user is in task router page, then navigate to restaurant specific page
    if (assignedTaskId && isAgentOnTRPage) {
      const task = tasksById[assignedTaskId];
      if (task?.data?.restaurant_code) {
        dispatch(updateFetchingTaskStatus(false));
        if (isTaskAssignmentMode) {
          sendTaskMessage({ ...task, event: TaskStatuses.assignedAck });
          dispatch(startLoading({}));
        }
        logger.info({
          restaurantCode: task.data.restaurant_code,
          taskId: assignedTaskId,
          message: `Before navigating to restaurant specific page for the task`,
          isTR: true,
        });
        sendAgentEvent(AgentEvents.assigned);
        if (getAuthRefactorFeatureFlag()) {
          navigate(location.state?.from || '/' + task.data.restaurant_code, {
            replace: true,
          });
        } else {
          navigate(location.state?.from || '/' + task.data.restaurant_code);
        }
      }
    }
  }, [assignedTaskId]);

  useEffect(() => {
    //Send login acknowledgement event for the first time
    const { isLoginAck } = getTaskRouterInfoFromLS() || {};
    if (!isLoginAck) {
      sendAgentEvent(AgentEvents.login);
      if (isTaskAssignmentMode) {
        sendAgentEvent(
          isAgentOnTRPage ? AgentEvents.available : AgentEvents.active
        );
      }
    }
  }, []);

  useEffect(() => {
    if (taskStatusChanged && userDetails) {
      const task = { ...taskStatusChanged };
      if (
        ![TaskStatuses.canceled, TaskStatuses.completed].includes(task.event)
      ) {
        if (isTaskAssignmentMode) {
          if (
            (sessionStatus === SessionStatus.start &&
              task.event === TaskStatuses.loaded) ||
            task.event === TaskStatuses.loadFail
          ) {
            sendTaskMessage({
              ...task,
              data: task.data,
            });
          } else if (
            [TaskStatuses.invalid, TaskStatuses.invalidAck].includes(task.event)
          ) {
            processInvalidTask(task, task.event);
          } else if (task.event === TaskStatuses.intervened) {
            dispatch(setStaffIntervention(true));
          }
        } else {
          sendTaskMessage(task);
        }
      } else if (
        [TaskStatuses.canceled, TaskStatuses.completed].includes(task.event) &&
        sessionStatus === SessionStatus.end
      ) {
        sendTaskMessage(task);
        dispatch(messagingActions.resetCarState());
      }
    }
  }, [taskStatusChanged, sessionStatus]);

  const sendAgentHeartbeatEvent = useCallback(() => {
    let eventName = isTaskAssignmentMode
      ? AgentEvents.statusAvailable
      : AgentEvents.available;
    if (isAgentOnTRPage) {
      switch (agentStatus) {
        case AgentStatuses.available: {
          eventName = isTaskAssignmentMode
            ? AgentEvents.statusAvailable
            : AgentEvents.available;
          break;
        }
        case AgentStatuses.unavailable: {
          eventName = isTaskAssignmentMode
            ? AgentEvents.statusUnavailable
            : AgentEvents.unavailable;
          break;
        }
        case AgentStatuses.training: {
          eventName = isTaskAssignmentMode
            ? AgentEvents.statusTraining
            : AgentEvents.training;
          break;
        }
        default: {
          eventName = isTaskAssignmentMode
            ? AgentEvents.statusAvailable
            : AgentEvents.available;
          break;
        }
      }
    } else {
      if (isTaskAssignmentMode) {
        eventName = AgentEvents.statusActive;
      }
      if (currentTaskId) {
        eventName = isTaskAssignmentMode
          ? AgentEvents.statusAssigned
          : AgentEvents.assigned;
      }
    }
    sendAgentEvent(eventName);
  }, [agentStatus, isAgentOnTRPage, isTaskAssignmentMode, currentTaskId]);

  useInterval(sendAgentHeartbeatEvent, config.AGENT_HEARTBEAT_EVENT_INTERVAL);

  const sendAgentEvent = (event: AgentEvents) => {
    const { id, username, email, firstName, lastName } =
      (userDetails as UserDetails) || {};
    const taskRouterInfo = getTaskRouterInfoFromLS();
    const { seqNumber, agentId, sessionId } = taskRouterInfo || {};
    const payload = {
      seq: seqNumber + 1,
      id: uuidv4(),
      agent_id: agentId,
      agent_type: AgentTypes.taskManager,
      session_id: sessionId,
      timestamp: new Date().toISOString(),
      event,
      data: {
        user_details: {
          id,
          username,
          email,
          firstName,
          lastName,
        },
      },
      restaurant_code: trWSLink,
    };
    const agentEventPayload = JSON.stringify(payload);
    sendMessage(agentEventPayload);
    saveTaskRouterInfoInLS({ ...taskRouterInfo, seqNumber: seqNumber + 1 });
  };

  const sendTaskMessage = useCallback(
    (task: ITask) => {
      const { id, username, email, firstName, lastName } =
        userDetails as UserDetails;
      const { taskArrivedTime, lane_id, ...otherValues } = task;
      const { restaurant_data, user_details, ...otherTaskDataValues } =
        task.data;
      const taskPayload = {
        ...otherValues,
        lane_id,
        timestamp: new Date().toISOString(),
        id: uuidv4(),
        data: {
          ...otherTaskDataValues,
          user_details: { id, username, email, firstName, lastName },
        },
      };
      const taskEventPayload = JSON.stringify(taskPayload);
      logger.info({
        message: 'Sending task event',
        event: task.event,
        payload: taskEventPayload,
      });
      sendMessage(JSON.stringify(taskPayload));
    },
    [userDetails]
  );

  const initializeTaskRouter = () => {
    //All the reset logic of task router slice and the default first steps of clean slate of task router data
    dispatch(stopLoading());
    logger.log('Session boundary state is reset in initializeTaskRouter call');
    dispatch(resetSessionBoundaryData());
    dispatch(resetTaskRouterData());
    dispatch(setNavigationViaTaskRouter(true));
    dispatch(messagingActions.resetCarState());
    sendAgentEvent?.(AgentEvents.available);
  };

  const processInvalidTask = (taskDetails: ITask, taskEvent: TaskStatuses) => {
    // Check ghost car alert triggered once.
    if (
      taskDetails.id !== LAST_GHOST_CAR_ALERT.task_id &&
      taskDetails.session_id !== LAST_GHOST_CAR_ALERT.session_id
    ) {
      /* Ghost Car Alert */
      logger.log({
        message: 'Ghost car alert displayed due to invalid task',
        task_id: taskDetails.id,
      });
      alert.show(AUTO_NAVIGATION_ALERT.GHOST_CAR.message, {
        type: AUTO_NAVIGATION_ALERT.GHOST_CAR.type,
        timeout: config.ALERT_TIMEOUT,
      });
      LAST_GHOST_CAR_ALERT = {
        task_id: taskDetails.id,
        session_id: taskDetails.session_id,
      };
    }

    if (isAgentOnTRPage && taskEvent === TaskStatuses.invalid) {
      //If the agent is in the Task router page itself, send the task_invalid_ack event and then reset the data
      sendTaskMessage({
        ...taskDetails,
        event: TaskStatuses.invalidAck,
      });
      initializeTaskRouter();
    } else if (!isAgentOnTRPage && taskEvent === TaskStatuses.invalidAck) {
      //If the agent has navigated to HITL restaurant specific page
      sendTaskMessage({
        ...taskDetails,
        event: taskEvent,
      });
      logger.log({
        message:
          'Call navigateToTaskRouterPage after task status becomes invalidAck on HITL restaurant specific page in processInvalidTask',
        moreInfo: JSON.stringify(taskDetails),
      });
      navigateToTaskRouterPage();
    }
  };

  const processTaskRouterMessage = () => {
    const latestTaskDetails = JSON.parse(lastMessage?.data);

    const {
      event = TaskStatuses.none,
      data: {
        task_id: taskId = '',
        restaurant_code: restaurantCode = '',
        user_details: userDetailsFromWSRes = {},
      } = {},
      session_id: sessionId,
      lane_id: laneId,
    } = latestTaskDetails;
    logger.info({
      restaurantCode,
      taskId,
      message: `useTaskRouterWS: Received TR message with event: ${event}`,
      moreInfo: lastMessage?.data,
      isTR: true,
    });

    if (event === AgentEvents.loginAck) {
      updateTaskRouterInfoInLS({ isLoginAck: true });
      return;
    } else if (
      event === TaskStatuses.agentInactiveForceLogout &&
      !isAgentInSession
    ) {
      logger.info({
        restaurantCode,
        taskId,
        message: `useTaskRouterWS: Logging out the user as received event is ${event}`,
        moreInfo: lastMessage?.data,
        isTR: true,
      });

      /* Agent Inactivity Alert at logout */
      alert.show(AUTO_NAVIGATION_ALERT.AGENT_INACTIVITY.message, {
        type: AUTO_NAVIGATION_ALERT.AGENT_INACTIVITY.type,
        timeout: config.ALERT_TIMEOUT,
      });

      logout();
      return;
    } else if (
      [
        TaskStatuses.offeredAck,
        TaskStatuses.exception,
        TaskStatuses.accepted,
        TaskStatuses.loaded,
        TaskStatuses.loadedAck,
        TaskStatuses.assignedAck,
        TaskStatuses.invalidAck,
        TaskStatuses.completeAck,
        TaskStatuses.info,
        AgentEvents.availableAck,
        AgentEvents.unavailableAck,
        AgentEvents.active,
        AgentEvents.activeAck,
        AgentEvents.statusAvailable,
        AgentEvents.statusUnavailable,
        AgentEvents.statusTraining,
        AgentEvents.statusAssigned,
        AgentEvents.statusActive,
      ].includes(event)
    ) {
      return;
    }

    if (taskId) {
      logger.info({
        restaurantCode,
        message: `useTaskRouterWS: Processing event with taskId: ${taskId}`,
        taskId,
        isTR: true,
      });
      if (tasksById[taskId]) {
        //Task status changed
        logger.info({
          restaurantCode,
          message: `useTaskRouterWS: Message is of an existing task: ${taskId}`,
          taskId,
          isTR: true,
        });

        if (userDetailsFromWSRes.id === userDetails?.id) {
          logger.info({
            restaurantCode,
            message: `useTaskRouterWS: Message is from the same logged in agent with taskId: ${taskId}`,
            taskId,
            isTR: true,
          });
          if (event === TaskStatuses.assigned) {
            logger.info({
              restaurantCode,
              message: `useTaskRouterWS: Agent has been assigned this task: ${taskId}`,
              taskId,
              isTR: true,
            });
            dispatch(messagingActions.carEnter());
          } else if (isTaskAssignmentMode && event === TaskStatuses.rejected) {
            dispatch(endSession(SessionEndReasons.noAgentActivity));
            return;
          }
          dispatch(
            updateTaskStatus({
              taskId,
              event,
              sessionId,
              laneId,
            })
          );
        } else {
          logger.info({
            restaurantCode,
            message: `useTaskRouterWS: Message is from the different agent with taskId: ${taskId}`,
            taskId,
            isTR: true,
          });
          if (
            event === TaskStatuses.timeout &&
            tasksById[taskId].event === TaskStatuses.assigned
          ) {
            //Received timeout from other agent and current agent is not assigned this task
            logger.info({
              restaurantCode,
              message:
                'Received timeout from other agent and current agent is not assigned this task - No further processing',
              taskId,
              isTR: true,
            });
          } else {
            logger.info({
              restaurantCode,
              message: 'Updating the task status as rejected',
              taskId,
              isTR: true,
            });
            dispatch(
              updateTaskStatus({ taskId, event: TaskStatuses.rejected })
            );
          }
        }
      } else {
        //Received a new task
        if (isTaskAssignmentMode) {
          if (event === TaskStatuses.assigned && !currentTaskId) {
            logger.info({
              restaurantCode,
              message: `useTaskRouterWS: New task is being assigned to agent`,
              taskId,
              isTR: true,
            });
            dispatch(addNewTask(latestTaskDetails));
            dispatch(
              updateTaskStatus({
                taskId,
                event,
                sessionId,
                laneId,
              })
            );
            dispatch(messagingActions.carEnter());
          }
        } else {
          if (
            event === TaskStatuses.offered &&
            restaurantAccessLevels?.[restaurantCode]?.role !==
              RolePermissions.Limited
          ) {
            logger.info({
              restaurantCode,
              message: `Task offered - taskId: ${taskId} - restaurantCode: ${restaurantCode}`,
              taskId,
              isTR: true,
            });
            dispatch(addNewTask(latestTaskDetails));
          }
        }
      }
    }
  };

  const handleAgentAvailability = useCallback((agentStatus: AgentStatuses) => {
    if (agentStatus === AgentStatuses.unavailable) {
      //Marking agent as unavailable
      sendAgentEvent(AgentEvents.unavailable);
      dispatch(setAgentStatus(agentStatus));
    } else if (agentStatus === AgentStatuses.available) {
      //Marking agent as available
      sendAgentEvent(AgentEvents.available);
      dispatch(resetTaskRouterData());
    } else if (agentStatus === AgentStatuses.training) {
      sendAgentEvent(AgentEvents.training);
      dispatch(setAgentStatus(agentStatus));
    }
  }, []);

  return {
    handleAgentAvailability,
    sendTaskMessage,
    sendAgentEvent,
    initializeTaskRouter,
    isTaskAssignmentMode,
  };
};

export default useTaskRouterWS;
