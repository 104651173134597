import { useAlert } from 'react-alert';
import { useAppSelector } from '../../app/hooks';

/* Selectors */
import { selectConfig } from '../../redux/features/config/config.selector';
import { selectSessionEndReason } from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import { selectTaskStatusChanged } from '../../redux/features/taskRouter/taskRouter.selector';
import { selectIsStaffIntervenedBeforeCarExit } from '../../selectors/message';

/* Constants */
import AUTO_NAVIGATION_ALERT from '../../constants/autoNavigationAlert';
import { SessionEndReasons } from '../../redux/features/sessionBoundary/sessionBoundary.constants';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import logger from '../../utils/logger';

const useTaskRouterInformativeMessages = () => {
  const alert = useAlert();
  const config = useAppSelector(selectConfig);
  const sessionEnd = useAppSelector(selectSessionEndReason);
  const taskStatusChanged = useAppSelector(selectTaskStatusChanged);
  const isStaffIntervenedBeforeCarExit = useAppSelector(
    selectIsStaffIntervenedBeforeCarExit
  );

  const populateInformativeAlerts = () => {
    if (taskStatusChanged) {
      const { event } = taskStatusChanged;

      const successfulEndReasons: Array<SessionEndReasons> = [
        SessionEndReasons.carExit,
        SessionEndReasons.killSwitchClicked,
        SessionEndReasons.cartEmpty,
      ];
      const unsuccessfulEndReasons: Array<SessionEndReasons> = [
        SessionEndReasons.carExit,
        SessionEndReasons.cartEmpty,
      ];

      if (
        event === TaskStatuses.completed &&
        successfulEndReasons.includes(sessionEnd as SessionEndReasons) &&
        !isStaffIntervenedBeforeCarExit
      ) {
        /* Successful order alert */
        logger.log({
          message: 'Successful order alert displayed',
          event,
          sessionEnd,
          isStaffIntervenedBeforeCarExit,
        });
        alert.show(AUTO_NAVIGATION_ALERT.SUCCESSFUL_ORDER.message, {
          type: AUTO_NAVIGATION_ALERT.SUCCESSFUL_ORDER.type,
          timeout: config.ALERT_TIMEOUT,
        });
      } else if (
        (event !== TaskStatuses.completed &&
          sessionEnd === SessionEndReasons.killSwitchClicked) ||
        (event === TaskStatuses.completed &&
          sessionEnd === SessionEndReasons.killSwitchClicked &&
          isStaffIntervenedBeforeCarExit) ||
        (event === TaskStatuses.loaded &&
          sessionEnd === SessionEndReasons.cartEmpty) ||
        (isStaffIntervenedBeforeCarExit &&
          sessionEnd === SessionEndReasons.carExit) ||
        ([TaskStatuses.canceled, TaskStatuses.canceledAck].includes(event) &&
          unsuccessfulEndReasons.includes(sessionEnd as SessionEndReasons)) ||
        (event === TaskStatuses.agentInterception &&
          unsuccessfulEndReasons.includes(sessionEnd as SessionEndReasons))
      ) {
        /* Unsuccessful order alert for kill switch clicked without completing task */
        /* Unsuccessful order alert for staff intervention */
        logger.log({
          message: 'Unsuccessful order alert displayed',
          event,
          sessionEnd,
          isStaffIntervenedBeforeCarExit,
        });
        alert.show(AUTO_NAVIGATION_ALERT.UNSUCCESSFUL_ORDER.message, {
          type: AUTO_NAVIGATION_ALERT.UNSUCCESSFUL_ORDER.type,
          timeout: config.ALERT_TIMEOUT,
        });
      } else if (
        taskStatusChanged?.event === TaskStatuses.loaded &&
        sessionEnd === SessionEndReasons.carExit
      ) {
        /* Ghost car alert for invalid task */
        logger.log({
          message: 'Ghost car alert displayed',
          event: taskStatusChanged?.event,
          sessionEnd,
          isStaffIntervenedBeforeCarExit,
        });
        alert.show(AUTO_NAVIGATION_ALERT.GHOST_CAR.message, {
          type: AUTO_NAVIGATION_ALERT.GHOST_CAR.type,
          timeout: config.ALERT_TIMEOUT,
        });
      }
    }
  };

  return {
    populateInformativeAlerts,
  };
};

export default useTaskRouterInformativeMessages;
