import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { MAX_CART_ITEM_QUANTITY } from '../constants';
import { clearCart } from '../reducers/cartSlice';
import { addHypothesisItemToCart } from '../reducers/menuSlice';
import { selectIsAIAutoMode } from '../redux/features/ai/ai.selector';
import { selectFinalOrderSubmitted } from '../redux/features/sessionBoundary/sessionBoundary.selectors';
import { selectHypothesisFrame } from '../selectors/message';

const useHypothesisFrames = () => {
  const dispatch = useAppDispatch();
  const isAIAutoMode = useAppSelector(selectIsAIAutoMode);
  const hypothesisFrame = useAppSelector(selectHypothesisFrame);
  const isFinalOrderSubmitted = useAppSelector(selectFinalOrderSubmitted);

  const shouldAddHypothesisItems = isAIAutoMode;

  useEffect(() => {
    if (!isFinalOrderSubmitted && shouldAddHypothesisItems) {
      hypothesisFrame?.data?.hypotheses?.forEach((hypothesis) => {
        const orderItems = hypothesis.order_items;
        dispatch(clearCart()); // Clear the cart and build a new one to avoid the comparison between cart items and order items when hypothesis evolves(update quantity or modifiers)

        orderItems.forEach((orderItem) => {
          const { quantity } = orderItem;
          const transformedQuantity = Math.floor(quantity); //Added for temporary purpose only as quantity will never be in decimals

          dispatch(
            addHypothesisItemToCart({
              ...orderItem,
              quantity: Math.min(transformedQuantity, MAX_CART_ITEM_QUANTITY),
            })
          );
        });
      });
    }
  }, [hypothesisFrame, isFinalOrderSubmitted, shouldAddHypothesisItems]);
};

export default useHypothesisFrames;
