import { ICachedMenuSlice } from '../redux/features/cachedMenu/cachedMenu.props';

export const getMenuVersion = (args: {
  menu: any;
  restaurant: any;
  taskRouter: any;
  cachedMenu: ICachedMenuSlice;
}) => {
  const { menu, restaurant, taskRouter, cachedMenu } = args;
  const { selectedMenuVersion, prodLiveVersion } = menu || {};

  if (taskRouter?.currentTaskId) {
    const restaurantCode = restaurant.selectedRestaurantDetails?.restaurantCode;
    const cachedEntry = cachedMenu.menusOrder?.find(
      ({ restaurantId }) => restaurantId === restaurantCode
    );

    return cachedEntry?.menuVersion;
  } else if (['', 'latest'].includes(selectedMenuVersion)) {
    return prodLiveVersion?.commitId;
  } else if (selectedMenuVersion) {
    return selectedMenuVersion;
  }
};
