import { useAppSelector } from '../../app/hooks';
import {
  selectFinalTextFrames,
  selectInProgressHypothesisTextFrames,
  selectSBFinalTextFrames,
  selectSBInProgressHypothesisTextFrames,
  selectSentTextFrames,
} from '../../selectors/message';
import { ISOStringToEpoch } from '../../utils/network';

function useTranscriptMessages() {
  const finalTextFrames = useAppSelector(selectFinalTextFrames);
  const inProgressHypothesisTextFrames = useAppSelector(
    selectInProgressHypothesisTextFrames
  );
  const sentTextFrames = useAppSelector(selectSentTextFrames);
  const sbFinalTextFrames = useAppSelector(selectSBFinalTextFrames);
  const sbInProgressHypothesisTextFrames = useAppSelector(
    selectSBInProgressHypothesisTextFrames
  );

  const buildMessages = (textFrames: any[], hypothesisFrames: any[]) => {
    const outputMessages = textFrames.sort(
      (a, b) => ISOStringToEpoch(a.timestamp) - ISOStringToEpoch(b.timestamp)
    );

    if (hypothesisFrames.length) {
      const lastHypothesis = hypothesisFrames[hypothesisFrames.length - 1];
      outputMessages.push(lastHypothesis);
    }

    return outputMessages;
  };

  const messagesList = buildMessages(
    [...finalTextFrames, ...sentTextFrames],
    inProgressHypothesisTextFrames
  );
  const sessionBufferMessagesList = buildMessages(
    [...sbFinalTextFrames],
    sbInProgressHypothesisTextFrames
  );

  return { messagesList, sessionBufferMessagesList };
}

export default useTranscriptMessages;
