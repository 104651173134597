import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import CartItemDetail from '../../components/ItemBuilder/menu/CartItemDetail';
import { selectCartItems } from '../../selectors/cart';
import { selectSelectedItemCartId } from '../../selectors/dialog';
import { SetCartItemRef } from '../../types';
import { useAppSelector } from '../../app/hooks';
import { selectFeatureFlagAutoCombo } from '../../redux/features/config/config.selector';
import { selectAutoComboCartItems } from '../../redux/features/autoCombo/autoCombo.selector';
import { isValidCartSelector } from '../../utils/cart';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  conatiner: {
    padding: `${spacing(7)} ${spacing(1)} 0 ${spacing(1)}`,
  },
  title: {
    fontStyle: 'italic',
  },
}));

interface OwnProps {
  setCartItemRef: SetCartItemRef;
}

const ItemBuilder = memo(function (props: OwnProps) {
  const { setCartItemRef } = props;
  const classes = useStyles(props);
  const cartItems = useSelector(selectCartItems);
  const selectedItemCartId = useSelector(selectSelectedItemCartId);
  const validCart = useSelector(isValidCartSelector);
  const autoComboEnabled = useAppSelector(selectFeatureFlagAutoCombo);
  const autoCombinedCartItems = useAppSelector(selectAutoComboCartItems);
  const cartItem =
    validCart && autoComboEnabled && Object.values(autoCombinedCartItems).length
      ? autoCombinedCartItems[selectedItemCartId]
      : cartItems?.[selectedItemCartId];

  return (
    <div id="itemBuilder" className={classes.conatiner}>
      {selectedItemCartId && cartItem && !cartItem.autoCombinedCartItemIds ? (
        <CartItemDetail cartItem={cartItem} setCartItemRef={setCartItemRef} />
      ) : undefined}
    </div>
  );
});

export default ItemBuilder;
