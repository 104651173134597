import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectAgentInactivityTimer } from '../../redux/features/config/config.selector';
import Colors from '../../utils/color';
import DwindlingTimer from '../DwindlingTimer';

const useStyles = makeStyles((theme: Theme) => ({
  titleArea: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(8),
    justifyContent: 'space-between',
    '& >div': {
      height: '100%',
    },
  },
  actionArea: {
    borderTop: `${theme.spacing(0.125)} solid ${Colors.alto}`,
  },
  contentArea: {
    borderTop: `${theme.spacing(0.125)} solid ${Colors.alto}`,
    padding: `${theme.spacing(3)} !important`,
    '& p': {
      color: Colors.black,
      fontSize: '14px',
    },
  },
}));

export default function SessionTimeoutDialog({
  timeoutCallback,
  activeCallback,
}: {
  timeoutCallback: Function;
  activeCallback: Function;
}) {
  const { titleArea, actionArea, contentArea } = useStyles({});
  const maxTimerSeconds = Math.min(
    useAppSelector(selectAgentInactivityTimer) - 10,
    5
  );

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          maxWidth: '350px',
          width: '350px',
          height: '210px',
          alignSelf: 'self-start',
          zIndex: '10',
        },
      }}
    >
      <DialogTitle className={titleArea}>
        <div>Session Timeout</div>
        <DwindlingTimer
          maxTime={maxTimerSeconds}
          timeoutCallback={timeoutCallback}
        />
      </DialogTitle>
      <DialogContent className={contentArea}>
        <DialogContentText>
          Due to inactivity, you will soon be logged out.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={actionArea}>
        <Button
          variant="contained"
          color="secondary"
          data-testid="continueSession"
          onClick={() => activeCallback()}
        >
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
}
