import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { UserRestaurantsRole } from '../reducers/restaurantSlice';
import { selectActiveRestaurantCode } from '../selectors/restaurant';
import {
  RolePermissions,
  UserRestaurantGroupRoleInput,
} from '../types/restaurant';

export const restaurantAccessLevelsSelector = (state: RootState) =>
  state.restaurant.restaurantAccessLevels;

export const getSelectedRestaurantAccess = (
  selectedRestaurantCode: string | null,
  accessLevels: RestaurantAccess
): RolePermissions | null => {
  if (selectedRestaurantCode) {
    return accessLevels[selectedRestaurantCode]?.role || null;
  }
  return null;
};

export const selectedRestaurantAccessSelector = createSelector(
  selectActiveRestaurantCode,
  restaurantAccessLevelsSelector,
  getSelectedRestaurantAccess
);

export interface UserRestaurantGroupRole extends UserRestaurantGroupRoleInput {
  groupName: string;
}

const ROLE_ACCESS = {
  [RolePermissions.PrestoAdmin.toString()]: 0,
  [RolePermissions.PrestoCustomerSupport.toString()]: 1,
  [RolePermissions.RestaurantManager.toString()]: 2,
  [RolePermissions.RestaurantStaff.toString()]: 3,
  [RolePermissions.Limited.toString()]: 4,
};

export const hasRoleAccess = (
  neededRole: RolePermissions | null,
  highestAvailableRole: RolePermissions | null
) => {
  if (!neededRole) {
    return true;
  }
  if (!highestAvailableRole) {
    return false;
  }
  return ROLE_ACCESS[highestAvailableRole] <= ROLE_ACCESS[neededRole];
};

export const getHighestAccessRestaurant = (roles: UserRestaurantsRole[]) => {
  let highestRoleNumber;
  let highestRole;
  for (let role of roles) {
    if (
      highestRoleNumber === undefined ||
      ROLE_ACCESS[role.role] < highestRoleNumber
    ) {
      highestRoleNumber = ROLE_ACCESS[role.role];
      highestRole = role;
    }
  }
  if (highestRole) {
    return highestRole.restaurants.length && highestRole.restaurants[0];
  }
  return undefined;
};

export interface RestaurantAccess {
  [restaurantCode: string]: {
    role: RolePermissions;
  };
}

export const getRestaurantAccessLevels = (roles: UserRestaurantsRole[]) => {
  const accessLevels: RestaurantAccess = {};
  for (let role of roles) {
    for (let restaurant of role.restaurants) {
      if (!accessLevels[restaurant.restaurantCode]) {
        accessLevels[restaurant.restaurantCode] = { role: role.role };
      } else {
        if (
          ROLE_ACCESS[role.role] <
          ROLE_ACCESS[accessLevels[restaurant.restaurantCode].role]
        ) {
          accessLevels[restaurant.restaurantCode].role = role.role;
        }
      }
    }
  }
  return accessLevels;
};

export const getUserAccessLevel = (roles: UserRestaurantGroupRole[]) => {
  if (roles.length === 0) return RolePermissions.RestaurantManager;
  let accessLevel: RolePermissions = roles[0].permission;
  roles.forEach((role) => {
    if (!accessLevel) {
      accessLevel = role.permission;
    } else {
      accessLevel =
        ROLE_ACCESS[role.permission] > ROLE_ACCESS[accessLevel]
          ? accessLevel
          : role.permission;
    }
  });

  return accessLevel;
};
