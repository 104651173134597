import logger from './logger';

const AUTH_REFACTOR_FEATURE_FLAG = 'auth-refactor-feature-flag';

export const setAuthRefactorFeatureFlag = (flag: string) => {
  logger.info(`Setting auth refactor feature flag to ${flag}`);
  sessionStorage.setItem(AUTH_REFACTOR_FEATURE_FLAG, flag);
};

export const getAuthRefactorFeatureFlag = (): boolean => {
  const authRefactorFeatureFlag = sessionStorage.getItem(
    AUTH_REFACTOR_FEATURE_FLAG
  );

  return authRefactorFeatureFlag === 'true' ? true : false;
};

const AGENT_INACTIVITY_FEATURE_FLAG = 'agent-inactivity-feature-flag';

export const setAgentInactivityFeatureFlag = (flag: string) => {
  logger.info({ message: `Setting agent inactivity feature flag to ${flag}` });
  sessionStorage.setItem(AGENT_INACTIVITY_FEATURE_FLAG, flag);
};

export const getAgentInactivityFeatureFlag = (): boolean => {
  const agentInactivityFeatureFlag = sessionStorage.getItem(
    AGENT_INACTIVITY_FEATURE_FLAG
  );

  return agentInactivityFeatureFlag === 'true' ? true : false;
};
