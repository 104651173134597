import { useAppDispatch, useAppSelector } from '../app/hooks';
import { ArrowKeys } from '../constants/enums';
import { dialogActions } from '../reducers/dialogSlice';
import { selectCartItems } from '../selectors/cart';
import { selectSelectedItemCartId } from '../selectors/dialog';

const useOmniBarKeyboardActions = () => {
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector(selectCartItems);
  const selectedItemCartId = useAppSelector(selectSelectedItemCartId);
  const currentActiveIndex = Object.keys(cartItems).findIndex(
    (cartItemKey) => cartItemKey === selectedItemCartId + ''
  );

  const activateCartItem = (index: number) => {
    const targetCartItem = Object.values(cartItems)[index];
    dispatch(
      dialogActions.updateSelectedItem({
        item: targetCartItem,
        itemCartId: targetCartItem.cartItemId,
      })
    );
    dispatch(dialogActions.setModGroupTabIndex(0));
    dispatch(dialogActions.updateSelectedModGroup());
  };

  const processCartItemSelectionKey = (eventKey: any) => {
    if (
      Object.keys(cartItems).length <= 1 &&
      [ArrowKeys.arrowDown, ArrowKeys.arrowUp].includes(eventKey)
    ) {
      return;
    }

    let newIndex = Object.keys(cartItems).length;

    switch (eventKey) {
      case ArrowKeys.arrowUp: {
        newIndex = currentActiveIndex - 1;
        break;
      }
      case ArrowKeys.arrowDown: {
        newIndex = currentActiveIndex + 1;
        break;
      }
    }

    if (
      newIndex !== currentActiveIndex &&
      newIndex >= 0 &&
      newIndex < Object.keys(cartItems).length
    )
      activateCartItem(newIndex);
  };

  return {
    activateCartItem,
    processCartItemSelectionKey,
  };
};

export default useOmniBarKeyboardActions;
