import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ItemBuilder from '../../features/item-builder/ItemBuilder';
import useAIMessaging from '../../features/messaging/useAIMessaging.hooks';
import useHypothesisFrames from '../../hooks/useHypothesisFrames.hooks';
import { considerTimePeriods } from '../../reducers/menuSlice';
import { selectCartModality } from '../../selectors/cart';
import {
  selectActiveRestaurantTimezone,
  selectedStageSelector,
} from '../../selectors/restaurant';
import { ICartItemRefs, SetCartItemRef } from '../../types';
import Colors from '../../utils/color';
import { TIME_PERIOD_CHECK_INTERVAL } from '../../utils/constants';
import AIStatusBar from '../AIStatusBar';
import IntentsSection from '../IntentsSection';
import RightBar from '../Layout/RightBar';
import { OmniBar } from '../OmniBar/OmniBar';
import TimeBasedMenuNotification from '../TimeBasedMenuNotification';
import TranscriptSliderArea from '../TranscriptSliderArea';

interface IMainContentStyleProps {
  showAIStatus: boolean;
}

const useStyles = makeStyles<Theme, IMainContentStyleProps>(({ spacing }) => ({
  mainContentClass: {
    height: '100%',
    display: 'flex',
    backgroundColor: Colors.porcelain,
  },
  flexHeightContainerClass: {
    height: ({ showAIStatus }) =>
      showAIStatus
        ? `calc(100% - ${spacing(18)})`
        : `calc(100% - ${spacing(12)})`,
    overflowY: 'auto',
    overflowX: 'hidden',
    border: `${spacing(0.125)} solid ${Colors.alto}`,
    borderTop: 'none',
    position: 'relative',
  },
  leftMiddlePaneContainerClass: {
    position: 'relative',
    display: 'flex',
    width: `calc(100% - 360px)`,
  },
  leftPaneClass: {
    display: 'flex',
    height: '100%',
    width: '360px',
    wordBreak: 'break-word',
    border: `${spacing(0.125)} solid ${Colors.alto}`,
  },
  middlePaneClass: {
    height: '100%',
    width: `calc(100% - 360px)`,
  },
  rightPaneContainerClass: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    border: `${spacing(0.125)} solid ${Colors.alto}`,
    width: '360px',
  },
}));

const MainContent = () => {
  const { isAIActive, isAIAutoMode } = useAIMessaging();
  const showAIStatus = isAIActive && isAIAutoMode;
  const {
    mainContentClass,
    leftPaneClass,
    middlePaneClass,
    leftMiddlePaneContainerClass,
    rightPaneContainerClass,
    flexHeightContainerClass,
  } = useStyles({ showAIStatus });
  const dispatch = useAppDispatch();
  const timezone = useAppSelector(selectActiveRestaurantTimezone);
  const stage = useAppSelector(selectedStageSelector);
  const modality = useAppSelector(selectCartModality);
  const cartItemRefs = useRef<ICartItemRefs>({});
  const setCartItemRef: SetCartItemRef = (
    cartItemId: number,
    ref: HTMLDivElement | null
  ) => {
    cartItemRefs.current[cartItemId] = ref;
  };

  useEffect(() => {
    if (timezone) {
      const timer = setInterval(() => {
        dispatch(considerTimePeriods({ modality, timezone, stage }));
      }, TIME_PERIOD_CHECK_INTERVAL);
      return () => {
        clearInterval(timer);
      };
    }
  }, [timezone, dispatch, modality, stage]);

  useHypothesisFrames();

  return (
    <div id="main-content" className={mainContentClass}>
      <div id="left-middle-pane" className={leftMiddlePaneContainerClass}>
        <div id="left-pane" className={leftPaneClass}>
          <TranscriptSliderArea />
          <IntentsSection />
        </div>
        <div id="middle-pane" className={middlePaneClass}>
          <div className={flexHeightContainerClass}>
            <TimeBasedMenuNotification />
            <ItemBuilder setCartItemRef={setCartItemRef} />
          </div>
          {showAIStatus && <AIStatusBar />}
          <OmniBar />
        </div>
      </div>
      <div id="right-pane" className={rightPaneContainerClass}>
        <RightBar />
      </div>
    </div>
  );
};

export default MainContent;
