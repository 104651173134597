import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { Theme } from '@mui/material';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import * as React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectAsrcPlayer, selectIsPlaying } from '../../selectors/message';

const useStyles = makeStyles<Theme>(({}) => ({
  boxClass: {
    width: '360px',
    height: '40px',
  },
}));

export const VolumeSlider = () => {
  const { boxClass } = useStyles();

  const [value, setValue] = React.useState(0);
  const isPlaying = useAppSelector(selectIsPlaying);
  const asrcPlayer = useAppSelector(selectAsrcPlayer);
  const handleVolumeChange = (
    event: Event,
    newValue: number | Array<number>
  ) => {
    if (asrcPlayer.audioContext) {
      asrcPlayer.gainNode?.gain.setValueAtTime(
        10 ** ((newValue as number) / 20),
        asrcPlayer.audioContext.currentTime
      );
      setValue(newValue as number);
    }
  };

  return (
    <>
      {isPlaying && (
        <Box className={boxClass}>
          <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <VolumeDown />
            <Slider
              aria-label="Volume"
              min={-30} // decibel
              max={30} // decibel
              value={value}
              onChange={handleVolumeChange}
            />
            <VolumeUp />
          </Stack>
        </Box>
      )}
    </>
  );
};
