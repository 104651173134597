import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { update } from '../../redux/features/config/config.slice';
import { useSelector } from 'react-redux';
import { selectActiveRestaurantCode } from '../../selectors/restaurant';
import { setAuthRefactorFeatureFlag } from '../../utils/session-storage';
import { setAgentInactivityFeatureFlag } from '../../utils/session-storage';

const useUnleashHooks = () => {
  const updateContext = useUnleashContext();
  const audioBackend = useFlag('audio_backend');
  const autoCombo = useFlag('auto_combo');
  const authRefactor = useFlag('auth_refactor');
  const agentInactivityRefactor = useFlag('agent_inactivity_refactor');

  const dispatch = useAppDispatch();
  const restaurantCode = useSelector(selectActiveRestaurantCode);

  useEffect(() => {
    dispatch(update({ FEATURE_FLAG_AUDIO_BACKEND: audioBackend }));
  }, [audioBackend]);

  useEffect(() => {
    dispatch(update({ FEATURE_FLAG_AUTO_COMBO: autoCombo }));
  }, [autoCombo]);

  useEffect(() => {
    setAgentInactivityFeatureFlag((!!agentInactivityRefactor).toString());
  }, [agentInactivityRefactor]);

  useEffect(() => {
    const updateRestaurantCode = async () => {
      await updateContext({ userId: restaurantCode });
    };
    updateRestaurantCode();
  }, [restaurantCode]);

  useEffect(() => {
    setAuthRefactorFeatureFlag((!!authRefactor).toString());
  }, [authRefactor]);
};

export default useUnleashHooks;
