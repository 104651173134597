import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useActivityTracking from '../../hooks/useActivityTracking.hooks';
import { selectIsReasonsDialogOpen } from '../../redux/features/ai/ai.selector';
import {
  selectShowOrderSubmissionAlert,
  selectIsSessionTimeoutDialogOpen,
} from '../../redux/features/sessionBoundary/sessionBoundary.selectors';
import InterceptionReasonsDialog from '../InterceptionReasonsDialog';
import MainContent from '../MainContent';
import RestaurantStaffDialog from '../RestaurantStaff';
import SessionTimeoutDialog from '../SessionTimeoutDialog';
import { selectConfig } from '../../redux/features/config/config.selector';
import OrderSubmissionAlert from '../OrderSubmissionAlert';
import { setShowOrderSubmissionAlert } from '../../redux/features/sessionBoundary/sessionBoundary.slice';
import { selectIsCarPresent } from '../../selectors/message';
import { clearMessages } from '../../reducers/messagingSlice';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  bodyClass: {
    height: `calc(100vh - ${spacing(8)})`,
  },
}));

const Body = () => {
  const { bodyClass } = useStyles();
  const isReasonsDialogOpen = useAppSelector(selectIsReasonsDialogOpen);
  const isSessionTimeoutDialogOpen = useAppSelector(
    selectIsSessionTimeoutDialogOpen
  );
  const { onIdle, handleAgentSessionContinuation } = useActivityTracking();
  const dispatch = useAppDispatch();

  const showOrderSubmissionAlert = useAppSelector(
    selectShowOrderSubmissionAlert
  );
  const config = useAppSelector(selectConfig);
  const isCarPresent = useAppSelector(selectIsCarPresent);

  useEffect(() => {
    if (showOrderSubmissionAlert) {
      if (!isCarPresent) dispatch(clearMessages());

      const timer = setTimeout(() => {
        dispatch(setShowOrderSubmissionAlert(false));
      }, config.ORDER_SUBMISSION_ALERT_TIMEOUT);
      return () => {
        clearInterval(timer);
      };
    }
  }, [
    showOrderSubmissionAlert,
    config.ORDER_SUBMISSION_ALERT_TIMEOUT,
    isCarPresent,
  ]);
  return (
    <div data-testid="body" className={bodyClass}>
      <MainContent />
      <RestaurantStaffDialog />
      {isReasonsDialogOpen && <InterceptionReasonsDialog />}
      {isSessionTimeoutDialogOpen && (
        <SessionTimeoutDialog
          timeoutCallback={onIdle}
          activeCallback={handleAgentSessionContinuation}
        />
      )}
      {showOrderSubmissionAlert && <OrderSubmissionAlert />}
    </div>
  );
};

export default Body;
