import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { getValidCartItems } from '../../../utils/cart';

const selectAutoCombo = (state: RootState) => state.autoCombo;
const selectAutoComboCartItems = createSelector(
  selectAutoCombo,
  (state) => state.autoCombinedCartItems
);

const selectAutoComboCartItemsQuantity = createSelector(
  selectAutoCombo,
  (state) => state.autoCombinedCartItemsQuantity
);

const selectLastNonAutoCombinedCartItemId = createSelector(
  selectAutoCombo,
  (state) => {
    const items = Object.values(state.autoCombinedCartItems);
    const firstAutoCombinedCartItemIdx = items.findIndex(
      (item) =>
        item.autoCombinedCartItemIds && item.autoCombinedCartItemIds.length > 0
    );
    return items[firstAutoCombinedCartItemIdx - 1].cartItemId;
  }
);

const selectValidAutoComboCartItems = createSelector(
  selectAutoComboCartItems,
  (autoComboCartItems) => getValidCartItems(autoComboCartItems)
);

export {
  selectAutoComboCartItems,
  selectAutoComboCartItemsQuantity,
  selectValidAutoComboCartItems,
};
