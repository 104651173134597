import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAvailableCategoryWithTimePeriod } from '../../selectors/menu';
import Colors from '../../utils/color';
import { ParsedCategory } from '../../utils/menu';

interface INotificationProps {
  isExpanded: boolean;
}

const useStyles = makeStyles<Theme, INotificationProps>(({ spacing }) => ({
  notificationClass: {
    maxHeight: ({ isExpanded }) => (isExpanded ? 'none' : spacing(7)),
    background: Colors.spindle,
    padding: `${spacing(1)} ${spacing(9)} ${spacing(1)} ${spacing(1)}`,
    overflowY: 'hidden',
    overflowX: 'auto',
    position: 'absolute !important' as any,
    width: '100%',
    zIndex: 2,
  },
  buttonClass: {
    color: `${Colors.bluishCyan} !important`,
    position: 'absolute !important' as any,
    top: 0,
    right: 0,
    '&:hover': {
      backgroundColor: `transparent !important`,
    },
    '&> .MuiTouchRipple-root span': {
      backgroundColor: `transparent !important`,
    },
  },
}));

enum SizeOptions {
  Expand = 'EXPAND',
  Close = 'CLOSE',
}

const TimeBasedMenuNotification = () => {
  const availableCategoryWithTimePeriod = useSelector(
    selectAvailableCategoryWithTimePeriod
  );

  const [size, setSize] = useState(
    availableCategoryWithTimePeriod.length > 2 ? SizeOptions.Expand : ''
  );

  const { notificationClass, buttonClass } = useStyles({
    isExpanded: size === SizeOptions.Close,
  });

  if (!availableCategoryWithTimePeriod.length) return null;

  const handleSizeChange = () => {
    setSize(
      size === SizeOptions.Close ? SizeOptions.Expand : SizeOptions.Close
    );
  };

  const getNotification = (category: ParsedCategory) => {
    const activeTimePeriod =
      category.activeTimePeriod && !category.activeTimePeriod.alwaysEnabled
        ? ` (${category.activeTimePeriod.start}-${category.activeTimePeriod.end} ${category.activeTimePeriod.day})`
        : '';
    return (
      <Typography
        key={category.id}
        sx={{ lineHeight: 1.5, fontWeight: 'normal' }}
      >
        {category.name + activeTimePeriod}
      </Typography>
    );
  };

  return (
    <Box className={notificationClass}>
      {availableCategoryWithTimePeriod.length &&
        availableCategoryWithTimePeriod.map((category) =>
          getNotification(category)
        )}
      {size ? (
        <Button className={buttonClass} onClick={handleSizeChange}>
          {size}
        </Button>
      ) : null}
    </Box>
  );
};

export default TimeBasedMenuNotification;
