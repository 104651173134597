import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  AgentInterceptionType,
  messagingActions,
} from '../../reducers/messagingSlice';
import { selectIsAIAutoMode } from '../../redux/features/ai/ai.selector';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectCurrentTask } from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';
import { selectAIIndicator } from '../../selectors/restaurant';

function useAgentInterception() {
  const dispatch = useAppDispatch();

  const currentTaskId = useAppSelector(selectCurrentTask);
  const isAIEnabled = useAppSelector(selectAIIndicator);
  const isAIAutoMode = useAppSelector(selectIsAIAutoMode);

  const sendAgentInterception = (
    ...args: Parameters<typeof messagingActions.sendAgentInterception>
  ) => {
    //This event is like agent activity
    dispatch(messagingActions.sendAgentInterception(...args));

    //This event internally used by HITL to populate informatory messages
    const isTTSSentInterception =
      args?.[0]?.data?.type === AgentInterceptionType.TTS_SENT;
    if (currentTaskId && !isTTSSentInterception) {
      dispatch(
        updateTaskStatus({
          taskId: currentTaskId,
          event: TaskStatuses.agentInterception,
        })
      );
    }

    //This event to be used by POS to identify first agent interception
    if (isAIEnabled && isAIAutoMode) {
      dispatch(messagingActions.sendAgentFirstInterception(...args));
    }
  };

  return { sendAgentInterception };
}

export default useAgentInterception;
