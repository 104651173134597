import TimerIcon from '@mui/icons-material/Timer';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import logger from '../../utils/logger';

const useStyles = makeStyles((theme: Theme) => ({
  dwindlingTimerArea: {
    display: 'flex',
    alignItems: 'flex-end',
    '& >span': {
      fontSize: '14px',
      marginLeft: '2px',
      fontWeight: '500',
      lineHeight: '1.6',
    },
  },
  timeLeftArea: {
    textAlign: 'right',
    width: theme.spacing(2),
  },
  secondsLabelArea: {},
}));

type DwindlingTimerProps = {
  maxTime: number;
  timeoutCallback: Function;
};

const DwindlingTimer = ({ maxTime, timeoutCallback }: DwindlingTimerProps) => {
  const { dwindlingTimerArea, timeLeftArea, secondsLabelArea } = useStyles({});
  const [timeLeft, setTimeLeft] = useState(maxTime);

  useEffect(() => {
    const timer =
      timeLeft > 0 ? setInterval(() => setTimeLeft(timeLeft - 1), 1000) : null;

    if (timeLeft === 0) {
      logger.log('Timeout callback being called');
      timeoutCallback();
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft]);

  return (
    <div className={dwindlingTimerArea}>
      <TimerIcon />
      <span className={timeLeftArea}>{timeLeft}</span>
      <span className={secondsLabelArea}>s</span>
    </div>
  );
};

export default DwindlingTimer;
