import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const selectAI = (state: RootState) => state.ai;
const selectIsAIAutoMode = createSelector(selectAI, (ai) => ai.isAIAutoMode);
const selectIsAIEscalation = createSelector(
  selectAI,
  (ai) => ai.isAIEscalation
);
const selectAIStatus = createSelector(selectAI, (ai) => ai.aiStatus);
const selectIsReasonsDialogOpen = createSelector(
  selectAI,
  (ai) => ai.interceptionReasonsDialogOpen
);
const selectAIEscalationReason = createSelector(
  selectAI,
  (ai) => ai.aiEscalationReason
);
export {
  selectAIStatus,
  selectIsAIAutoMode,
  selectIsAIEscalation,
  selectIsReasonsDialogOpen,
  selectAIEscalationReason,
};
