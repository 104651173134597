import {useSelector} from 'react-redux';
import {useAppDispatch} from '../app/hooks';
import {selectOrderMessageData} from '../redux/features/sessionBoundary/sessionBoundary.selectors';
import {selectCartItems, selectHypothesisCartItems, selectValidCartItems} from '../selectors/cart';
import {startSendOrder, populateOrderRequests} from '../reducers/orderSlice';
import {ISendOrderData} from '../reducers/orderSlice.props';
import {selectIsAIAutoMode} from '../redux/features/ai/ai.selector';
import {useEffect} from 'react';

const useExternalOrderHandler = () => {
    const dispatch = useAppDispatch();
    const orderMessageData = useSelector(selectOrderMessageData);
    const cartItems = useSelector(selectCartItems);
    const isAIAutoMode = useSelector(selectIsAIAutoMode);
    const isOrderFromExternal = isAIAutoMode;
    const hypothesisCartItems = useSelector(selectHypothesisCartItems);

    useEffect(() => {
        const externalOrderItems = {...cartItems, ...hypothesisCartItems};
        if (
            isOrderFromExternal &&
            Object.values(orderMessageData).length &&
            Object.values(externalOrderItems).length
        ) {
            const cartItemIds = Object.values(externalOrderItems).reduce(
                (acc: number[], {cartItemId}) => {
                    if (cartItemId) acc.push(cartItemId);
                    return acc;
                },
                []
            ).sort();

            dispatch(startSendOrder(cartItemIds));

            const {
                request_id = '',
                final = false,
                coupons = [],
            } = orderMessageData as ISendOrderData;
            dispatch(
                populateOrderRequests({
                    requestId: request_id,
                    cartItemIds,
                    isFinal: final,
                    couponIds: Object.keys(coupons)
                })
            );
        }
    }, [orderMessageData, cartItems, hypothesisCartItems]);
};

export default useExternalOrderHandler;
