import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import OrderReview from '../../features/order-review/OrderReview';
import OrderTotalContainer from '../../features/order-review/OrderTotalContainer';
import Colors from '../../utils/color';
import { OrderActions } from '../OrderReview/OrderActions';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    border: `${theme.spacing(0.125)} solid ${Colors.alto}`,
    minWidth: '300px',
    maxWidth: '360px',
  },
  subContainer: {
    position: 'relative',
    flex: 1,
    maxHeight: 'calc(100vh - 164px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '10px',
  },
}));

export default function RightBar() {
  const classes = useStyles();

  return (
    <div id="rightBar" className={classes.container}>
      <div id="cartContainer" className={classes.subContainer}>
        <OrderReview />
      </div>
      <OrderTotalContainer />
      <OrderActions />
    </div>
  );
}
