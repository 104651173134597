import { Box, CardContent, Tab, Tabs, Theme, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import { makeStyles } from 'tss-react/mui';
import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import useAgentInterception from '../../../hooks/agentInterception/useAgentInterception.hooks';
import { cartActions } from '../../../reducers/cartSlice';
import { dialogActions } from '../../../reducers/dialogSlice';
import {
  AgentInterceptionType,
  InfoTransmissionMessage,
  messagingActions,
  TextFrameTransmissionMessage,
} from '../../../reducers/messagingSlice';
import { selectIsAIAutoMode } from '../../../redux/features/ai/ai.selector';
import { selectDialogSelectedModGroup } from '../../../selectors/dialog';
import {
  modSymbolMappingSelector,
  posSettingsSelector,
} from '../../../selectors/menu';
import { SetCartItemRef } from '../../../types';
import { getItemUnavailableMessage } from '../../../utils/TTSMessage';
import { CartItem } from '../../../utils/cart';
import Colors from '../../../utils/color';
import {
  isItem86edToday,
  ParsedMenuItem,
  ParsedModifierGroup,
  sortModGroups,
} from '../../../utils/menu';
import ModGroup from './ModGroup';

const useStyles = makeStyles()((theme: Theme) => ({
  menuItemCard: {
    marginTop: theme.spacing(2),
  },
  menuItemCardContent: {
    padding: '0px !important',
  },
  textFieldContainer: {
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  tabsContainer: {
    backgroundColor: Colors.gallery,
    '& .MuiTab-root': {
      backgroundColor: Colors.porcelain,
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      fontWeight: 'bold !important',
    },
    '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
      color: Colors.black,
      backgroundColor: Colors.white,
    },
  },
  menuItemTabPanel: {
    paddingTop: theme.spacing(1.5),
    '& > div': {
      padding: '0 !important',
    },
  },
  tabPanelBox: {
    padding: theme.spacing(2),
    borderColor: 'rgba(0, 0, 0, 0.12)',
    paddingTop: '0',
  },
}));

const TabPanel = ({
  children,
  index,
  value,
}: {
  children: React.ReactNode;
  index: number;
  value: number;
}) => {
  const { classes } = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`menu-item-choice-panel-${index}`}
      aria-labelledby={`menu-item-choice-panel-${index}`}
    >
      {value === index && (
        <Box className={classes.tabPanelBox}>
          <div className={classes.menuItemTabPanel}>{children}</div>
        </Box>
      )}
    </div>
  );
};

interface CartItemDetailProps {
  cartItem: CartItem;
  setCartItemRef: SetCartItemRef;
}

const CartItemDetail = ({ cartItem, setCartItemRef }: CartItemDetailProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const { cartItems } = useSelector((state: RootState) => state.cart);
  const modSymbolMapping = useSelector(modSymbolMappingSelector);
  const selectedModGroup = useSelector(selectDialogSelectedModGroup);
  const isAIAutoMode = useSelector(selectIsAIAutoMode);
  const posSettings = useSelector(posSettingsSelector);
  const { sendAgentInterception } = useAgentInterception();

  const sendAgentInterventionEvent = useCallback(
    (description?: string, type?: AgentInterceptionType) => {
      sendAgentInterception({
        data: {
          type: type || AgentInterceptionType.ORDER_CHANGED,
          description,
        },
      });
    },
    [sendAgentInterception]
  );

  const onTabChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: any) => {
      setTabValue(value);
    },
    []
  );

  const sendMessage = useCallback(
    (message: string) => {
      const payload: Partial<TextFrameTransmissionMessage> = {
        data: { payload: message, status: 'final' },
      };
      dispatch(messagingActions.sendMessage(payload as any));
    },
    [dispatch]
  );

  const onTabClick = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      if (cartItem.description) {
        sendMessage(cartItem.description);
        sendAgentInterventionEvent(
          `${cartItem.name} description is sent as TTS`,
          AgentInterceptionType.TTS_SENT
        );
      }
    },
    [cartItem.description, sendMessage, sendAgentInterventionEvent]
  );

  const onMenuItemModifierSelect = useCallback(
    (
      menuItem: ParsedMenuItem | undefined,
      modGroup: ParsedModifierGroup,
      pathToItem: string,
      selected: boolean,
      fromClick?: boolean
    ) => {
      if (!menuItem) return;
      if (isItem86edToday(menuItem)) {
        sendMessage(getItemUnavailableMessage(menuItem.name));
        return;
      }
      const message =
        'Set ' +
        selected +
        ' for ' +
        modGroup.name +
        ' modifier for menu item ' +
        menuItem.name;
      const {
        id,
        name,
        isUpsell: is_upsell,
        isUpsellPrompt: is_upsell_prompt,
        label,
      } = menuItem;
      const {
        id: modGroupId,
        name: modGroupName,
        isUpsell: modGroupIsUpsell,
        isUpsellPrompt: modGroupIsUpsellPrompt,
        label: modGroupLabel,
      } = modGroup;
      const payload: Partial<InfoTransmissionMessage> = {
        data: {
          type: 'METRIC',
          message: message,
          metadata: {
            menuItem: {
              id,
              name,
              ...(is_upsell !== undefined && { is_upsell }),
              ...(is_upsell_prompt !== undefined && { is_upsell_prompt }),
              ...(label && { label }),
            },
            modGroup: {
              id: modGroupId,
              name: modGroupName,
              ...(modGroupIsUpsell !== undefined && {
                is_upsell: modGroupIsUpsell,
              }),
              ...(modGroupIsUpsellPrompt !== undefined && {
                is_upsell_prompt: modGroupIsUpsellPrompt,
              }),
              ...(modGroupLabel && { label: modGroupLabel }),
            },
          },
        },
      };
      if (!isAIAutoMode) dispatch(messagingActions.sendInfo(payload as any));

      if (menuItem.name.toLocaleLowerCase() !== 'declined') {
        dispatch(
          cartActions.selectModifier({
            cartItemId: cartItem.cartItemId,
            menuItemId: menuItem.itemId,
            modGroupId: modGroup.id,
            selected,
            modCode: '',
            modSymbolMapping,
            pathToItem,
            posSettings,
          })
        );

        if (fromClick)
          sendAgentInterventionEvent(`${menuItem.name} added to order`);
      } else {
        dispatch(cartActions.deleteCartItem(cartItem.cartItemId.toString()));
        if (fromClick)
          sendAgentInterventionEvent(`${cartItem.name} removed from order`);

        const cartItemIds = Object.keys(cartItems)
          .filter((id) => id !== cartItem.cartItemId.toString())
          .map((id) => parseInt(id));
        const newCartItemId = Math.max(...cartItemIds);
        if (newCartItemId > 0) {
          dispatch(
            dialogActions.updateSelectedItem({
              item: cartItems[newCartItemId.toString()],
              itemCartId: newCartItemId,
            })
          );
        } else {
          dispatch(dialogActions.updateSelectedItem());
        }
        dispatch(dialogActions.setModGroupTabIndex(0));
        dispatch(dialogActions.updateSelectedModGroup());
      }
    },
    [
      cartItem,
      cartItems,
      dispatch,
      modSymbolMapping,
      sendAgentInterventionEvent,
      sendMessage,
    ]
  );

  const modifierGroups = sortModGroups(cartItem);

  return (
    <Card
      className={classes.menuItemCard}
      elevation={3}
      sx={{ borderRadius: 1.5 }}
    >
      <CardContent className={classes.menuItemCardContent}>
        <Tabs
          value={tabValue}
          onClick={onTabClick}
          onChange={onTabChange}
          aria-label={cartItem.name}
          TabIndicatorProps={{ style: { display: 'none' } }}
          className={classes.tabsContainer}
        >
          <Tooltip title={cartItem.description || ''} placement="right">
            <Tab
              label={cartItem.name}
              ref={(ref) => {
                setCartItemRef(cartItem.cartItemId, ref);
              }}
              style={{
                color: Colors.daintree,
                padding: '1rem',
                fontSize: '0.75rem',
              }}
            />
          </Tooltip>
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          {modifierGroups.map((modGroup: ParsedModifierGroup) => (
            <ModGroup
              key={`${modGroup.id}_display`}
              cartItem={cartItem}
              modGroup={modGroup}
              selectMenuItemModifier={onMenuItemModifierSelect}
              pathToItem={modGroup.id}
              isSelectedToFocus={selectedModGroup?.id === modGroup.id}
            />
          ))}
        </TabPanel>
      </CardContent>
    </Card>
  );
};

export default memo(CartItemDetail);
