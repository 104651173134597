import * as React from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Colors from '../../utils/color';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  container: {
    position: 'absolute',
    top: spacing(9),
    left: `calc(50% - ${spacing(20)})`,
    width: spacing(40),
    background: `${Colors.white} !important`,
    borderRadius: `${spacing(1)} !important`,
    border: `${spacing(0.125)} solid ${Colors.alto} !important`,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    color: Colors.daintree,
    zIndex: 3,
  },
  titleClass: {
    padding: `${spacing(2)} ${spacing(5)} ${spacing(2)} ${spacing(2.5)}`,
    borderBottom: `${spacing(0.125)} solid ${Colors.alto}`,
    fontSize: '1rem !important',
  },
  bodyClass: {
    padding: `${spacing(2)} ${spacing(5)} ${spacing(2)} ${spacing(2.5)}`,
  },
}));

const OrderSubmissionAlert = () => {
  const { container, titleClass, bodyClass } = useStyles();
  return (
    <div data-testid="order-submission-alert" className={container}>
      <Typography variant="subtitle2" className={titleClass}>
        Order Submitted!
      </Typography>
      <Typography variant="body2" className={bodyClass}>
        AI has submitted the order successfully.
      </Typography>
    </div>
  );
};

export default OrderSubmissionAlert;
