import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import {EntityMenuItem} from "../../reducers/messagingSlice";
import HypothesisCartItemModGroups from "./HypothesisItemModGroups";

interface IChildModGroup {
    hypothesisCartItemModifier: EntityMenuItem;
}

interface IChildModGroupStyling {
    hasChildren: boolean;
    isSelected?: boolean;
}

const useStyles = makeStyles<Theme, IChildModGroupStyling>((theme) => ({
    itemNameClass: {
        wordBreak: 'break-all',
        fontSize: `${theme.spacing(1.5)} !important`,
    },
    childModGroupClass: {
        fontSize: theme.spacing(1.5),
        paddingBottom: ({ hasChildren }) => (hasChildren ? theme.spacing(1) : 0),
    },
    itemPriceClass: {
        fontSize: `${theme.spacing(1.5)} !important`,
        paddingLeft: theme.spacing(2),
    },
}));

export default function HypothesisChildModGroup({ hypothesisCartItemModifier }: IChildModGroup) {
    const { itemNameClass, childModGroupClass} = useStyles({
        hasChildren: true,
    });

    return (
        <div
            className={`cartitem-child-modgroup ${childModGroupClass}`}
        >
            <div key={hypothesisCartItemModifier.id}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="subtitle2" className={itemNameClass}>
                        {hypothesisCartItemModifier.name}
                    </Typography>
                </Box>
                {hypothesisCartItemModifier && hypothesisCartItemModifier.children && <HypothesisCartItemModGroups hypothesisCartItemModGroups={hypothesisCartItemModifier.children} />}
            </div>
        </div>
    );
}
