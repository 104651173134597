import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import TaskRouterBody from '../components/TaskRouterBody';
import { selectWSConnectionMessage } from '../redux/features/taskRouter/taskRouter.selector';
import Colors from '../utils/color';
import logger from '../utils/logger';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterPageClass: {},
  errorMessageClass: {
    paddingLeft: spacing(1),
  },
  errorClass: {
    height: spacing(5),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.red,
    color: Colors.white,
    paddingLeft: spacing(2),
  },
}));

const TaskRouterPage = () => {
  const { taskRouterPageClass, errorClass, errorMessageClass } = useStyles();
  const {
    handleAgentAvailability,
    sendTaskMessage,
    initializeTaskRouter,
    isTaskAssignmentMode,
  } = useOutletContext<any>();
  const wsConnectionMessage = useAppSelector(selectWSConnectionMessage);

  useEffect(() => {
    logger.info({
      message: 'Task-router page component is mounted',
      isTR: true,
    });
    initializeTaskRouter();
  }, []);

  return (
    <div data-testid="task-router-page" className={taskRouterPageClass}>
      {wsConnectionMessage ? (
        <div className={errorClass}>
          <WarningAmberIcon />
          <span className={errorMessageClass}>{wsConnectionMessage}</span>
        </div>
      ) : (
        <TaskRouterBody
          handleAgentAvailability={handleAgentAvailability}
          sendTaskMessage={sendTaskMessage}
          isTaskAssignmentMode={isTaskAssignmentMode}
        />
      )}
    </div>
  );
};

export default TaskRouterPage;
