import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import {EntityMenuItem} from "../../reducers/messagingSlice";
import HypothesisChildModGroup from "./HypothesisItemChildModGroup";

interface ICartItemModGroups {
    hypothesisCartItemModGroups: EntityMenuItem[];
}

const useStyles = makeStyles((theme: Theme) => ({
    cartItemModGroupClass: {
        paddingLeft: theme.spacing(1.75),
    }
}));

export default function HypothesisCartItemModGroups({hypothesisCartItemModGroups}: ICartItemModGroups) {
    const { cartItemModGroupClass} =
        useStyles();
    return (
        <div className={`cartitem-mod-group ${cartItemModGroupClass}`}>
            {hypothesisCartItemModGroups.map((modifier) => (
                <HypothesisChildModGroup hypothesisCartItemModifier={modifier} key={modifier.id}/>
            ))}
        </div>
    );
}
