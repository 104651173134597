import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { CartItemAdditionTypes } from '../../constants/event';
import useAgentInterception from '../../hooks/agentInterception/useAgentInterception.hooks';
import { cartActions } from '../../reducers/cartSlice';
import { dialogActions } from '../../reducers/dialogSlice';
import { AgentInterceptionType } from '../../reducers/messagingSlice';
import { OrderStatus } from '../../reducers/orderSlice.constants';
import { CartItem, getAutoCombinedCartItemIdsCount } from '../../utils/cart';
import Colors, { sequentialItemColors } from '../../utils/color';
import { getMenuItemPrice } from '../../utils/menu';
import { printCurrency } from '../../utils/money';
import CartItemModGroups from './CartItemModGroups';
import OrderProgressBar from './OrderProgressBar';
import { useAppSelector } from '../../app/hooks';
import { selectFeatureFlagAutoCombo } from '../../redux/features/config/config.selector';
import { selectCartItemsQuantity } from '../../selectors/cart';

interface ICartItemReview {
  cartItem: CartItem;
  quantity: number;
  status?: OrderStatus;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(2),
    cursor: 'pointer',
  },
  content: {
    padding: `${theme.spacing(1.5)} !important`,
    '& > div.cartitem-mod-group': {
      padding: '0 !important',
    },
  },
  mainItemname: {
    lineHeight: '1.57 !important',
    fontSize: `${theme.spacing(1.5)} !important`,
    fontWeight: 'bold !important',
  },
  lineItemsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemPrice: {
    paddingLeft: theme.spacing(2),
    fontSize: '0.75rem !important',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      cursor: 'pointer',
    },
  },
  itemActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& .MuiButtonBase-root.MuiButton-root': {
      textTransform: 'none',
      color: Colors.black,
      fontWeight: 'bold',
      flexDirection: 'row-reverse',
      textAlign: 'right',
      padding: '0px',
    },
  },
  selectQuantity: {
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '&:before': {
      borderBottom: 'none !important',
    },
    '& .MuiSelect-select': {
      textAlign: 'center',
      minWidth: '40px !important',
      paddingRight: '2px !important',
      paddingLeft: '0px !important',
    },
  },
  autoCombinedText: {
    paddingBottom: theme.spacing(1),
  },
}));

export const CartItemReview = (props: ICartItemReview) => {
  const { cartItem, quantity, status } = props;
  const isAutoCombined =
    cartItem.autoCombinedCartItemIds &&
    cartItem.autoCombinedCartItemIds.length > 0;
  const cartItemRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sequenceId, cartItems } = useSelector(
    (state: RootState) => state.cart
  );
  const { selectedItemCartId } = useSelector(
    (state: RootState) => state.dialog
  );
  const { sendAgentInterception } = useAgentInterception();
  const quantityList: number[] = new Array(19).fill(0);

  const autoComboEnabled = useAppSelector(selectFeatureFlagAutoCombo);
  const cartItemsQuantity = useAppSelector(selectCartItemsQuantity);

  const sendAgentInterventionEvent = (description: string) => {
    sendAgentInterception({
      data: {
        type: AgentInterceptionType.ORDER_CHANGED,
        description,
      },
    });
  };

  const cloneCartItem = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (autoComboEnabled && cartItem.autoCombinedCartItemIds) {
      const autoCombinedCartItemIdsCount = getAutoCombinedCartItemIdsCount(
        cartItem.autoCombinedCartItemIds
      );
      Object.keys(autoCombinedCartItemIdsCount).forEach((cartItemId) =>
        dispatch(
          cartActions.updateQuantity({
            cartItemId,
            quantity:
              cartItemsQuantity[cartItemId] +
              autoCombinedCartItemIdsCount[cartItemId],
          })
        )
      );
    } else {
      dispatch(cartActions.cloneCartItem(cartItem.cartItemId.toString()));
      dispatch(dialogActions.cloneSelectedItem({ cartItemId: sequenceId + 1 }));
    }
    dispatch(dialogActions.setModGroupTabIndex(0));
    dispatch(dialogActions.updateSelectedModGroup());
    sendAgentInterventionEvent(`Clone ${cartItem.name} in the same order`);
  };

  const deleteCartItem = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (autoComboEnabled && cartItem.autoCombinedCartItemIds) {
      const autoCombinedCartItemIdsCount = getAutoCombinedCartItemIdsCount(
        cartItem.autoCombinedCartItemIds
      );
      Object.keys(autoCombinedCartItemIdsCount).forEach((cartItemId) =>
        dispatch(
          cartActions.updateQuantity({
            cartItemId,
            quantity:
              cartItemsQuantity[cartItemId] -
              autoCombinedCartItemIdsCount[cartItemId],
          })
        )
      );
    } else {
      dispatch(cartActions.deleteCartItem(cartItem.cartItemId.toString()));
      if (selectedItemCartId === cartItem.cartItemId) {
        const cartItemIds = Object.keys(cartItems)
          .filter((id) => id !== selectedItemCartId.toString())
          .map((id) => parseInt(id));
        const newCartItemId = Math.max(...cartItemIds);
        if (newCartItemId > 0) {
          dispatch(
            dialogActions.updateSelectedItem({
              item: cartItems[newCartItemId.toString()],
              itemCartId: newCartItemId,
            })
          );
        } else {
          dispatch(dialogActions.updateSelectedItem());
        }
        dispatch(dialogActions.setModGroupTabIndex(0));
        dispatch(dialogActions.updateSelectedModGroup());
      }
    }

    sendAgentInterventionEvent(`${cartItem.name} is removed from order`);
  };

  const updateQuantity = (newQuantity: number) => {
    if (autoComboEnabled && cartItem.autoCombinedCartItemIds) return; // not support for auto combined item

    dispatch(
      cartActions.updateQuantity({
        cartItemId: cartItem.cartItemId.toString(),
        quantity: newQuantity,
      })
    );
    sendAgentInterventionEvent(
      `Quantity of ${cartItem.name} changed to ${newQuantity}`
    );
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      updateQuantity(quantity - 1);
    }
  };
  const handleIncreaseQuantity = () => {
    if (quantity < 19) {
      updateQuantity(quantity + 1);
    }
  };

  const onCardClick = () => {
    if (isAutoCombined) return;
    dispatch(
      dialogActions.updateSelectedItem({
        item: cartItem,
        itemCartId: cartItem.cartItemId,
      })
    );
    dispatch(dialogActions.setModGroupTabIndex(0));
    dispatch(dialogActions.updateSelectedModGroup());
  };

  const itemColor = Colors.daintree;
  const itemCardBorder =
    selectedItemCartId === cartItem.cartItemId
      ? `3px solid ${itemColor}`
      : 'unset';

  useEffect(() => {
    if (selectedItemCartId === cartItem.cartItemId) {
      cartItemRef.current?.scrollIntoView();
    }
  }, [selectedItemCartId]);

  return (
    <Card
      id="cart-item"
      className={classes.card}
      elevation={3}
      sx={{
        borderRadius: 1.5,
        backgroundColor: `${
          cartItem.addedBy === CartItemAdditionTypes.AI
            ? Colors.aiItemIndicator
            : isAutoCombined
            ? Colors.gallery
            : Colors.white
        }`,
      }}
      onClick={onCardClick}
      ref={cartItemRef}
      style={{
        borderLeft: itemCardBorder,
        borderRight: itemCardBorder,
        borderTop: itemCardBorder,
      }}
    >
      <CardContent className={classes.content}>
        <div className={classes.lineItemsWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.mainItemname}
            style={{ color: itemColor }}
          >
            {cartItem.name}
          </Typography>
          <Typography variant="subtitle2" className={classes.itemPrice}>
            {printCurrency(
              getMenuItemPrice(cartItem, cartItem.modality, quantity),
              'USD'
            )}
          </Typography>
        </div>
        {isAutoCombined && (
          <Typography
            variant="subtitle2"
            className={`${classes.mainItemname} ${classes.autoCombinedText}`}
            style={{ color: itemColor }}
          >
            (Auto-combined)
          </Typography>
        )}
        {cartItem && cartItem.childModifierGroups && (
          <CartItemModGroups
            cartItem={cartItem}
            modGroupCartItem={cartItem}
            quantity={quantity}
            selectable={!isAutoCombined}
          />
        )}
        <div className={classes.actionsWrapper}>
          <div>
            <IconButton
              size="small"
              onClick={handleDecreaseQuantity}
              disabled={isAutoCombined}
            >
              <RemoveIcon />
            </IconButton>
            <Select
              value={quantity}
              size="small"
              variant="standard"
              onChange={(e) => updateQuantity(e.target.value as number)}
              MenuProps={{
                PaperProps: {
                  style: { maxHeight: 200 },
                },
              }}
              className={classes.selectQuantity}
              disabled={isAutoCombined}
            >
              {quantityList.map((q, index) => (
                <MenuItem key={`quantity-${index}`} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              size="small"
              onClick={handleIncreaseQuantity}
              disabled={isAutoCombined}
            >
              <AddIcon />
            </IconButton>
          </div>
          <div className={classes.itemActions}>
            <Button variant="text" onClick={deleteCartItem} size="small">
              Remove
            </Button>
            <Button variant="text" onClick={cloneCartItem} size="small">
              Duplicate
            </Button>
          </div>
        </div>
      </CardContent>
      <OrderProgressBar status={status} cartItem={cartItem} />
    </Card>
  );
};
