import { AgentTypes, EventTypes } from '../constants/event';

const filterBufferedEvents = (receivedBufferedEvents: any[]) => {
  /* Target events:
        1. ORDERBOARD: transcript
        2. LLM: hypothesis - cart items, order - to get current order sequence id
    */
  const transcriptMessages: any[] = [];
  const latestBufferedEvents = {
    hypothesis: false,
    order: false,
  };

  for (var i = receivedBufferedEvents.length - 1; i >= 0; i--) {
    const { event, agent_type } = receivedBufferedEvents[i] || {};

    if (agent_type === AgentTypes.orderBoard) {
      transcriptMessages.push(receivedBufferedEvents[i]);
    } else if (Object.values(latestBufferedEvents).includes(false)) {
      switch (event) {
        case EventTypes.hypothesis: {
          if (!latestBufferedEvents.hypothesis) {
            latestBufferedEvents.hypothesis = receivedBufferedEvents[i];
          }
          break;
        }
        case EventTypes.order: {
          if (!latestBufferedEvents.order) {
            latestBufferedEvents.order = receivedBufferedEvents[i];
          }
          break;
        }
      }
    }
  }

  //Populate all transcript messages first, followed by 'hypothesis', 'order'
  return [
    ...transcriptMessages.reverse(),
    ...(latestBufferedEvents.hypothesis
      ? [latestBufferedEvents.hypothesis]
      : []),
    ...(latestBufferedEvents.order ? [latestBufferedEvents.order] : []),
  ];
};

export { filterBufferedEvents };
