import logger from './logger';

export const getNiceVoice = () => {
  const all_voices = window.speechSynthesis.getVoices();
  return all_voices[49];
};

export const cyborgAlert = (alert: string, vol = 0.2) => {
  let msg = new SpeechSynthesisUtterance(alert.replace('~', ''));
  msg.volume = vol;
  msg.rate = 1;
  msg.voice = getNiceVoice();
  msg.onerror = (ev) => {
    // handle the tts error
  };
  msg.onend = (ev) => {
    if (!speechSynthesis.pending && !speechSynthesis.speaking) {
      speechSynthesis.cancel();
    }
  };
  speechSynthesis.speak(msg);
};
