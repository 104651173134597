import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectOrder = (state: RootState) => state.order;

const selectOrderValues = createSelector(
  selectOrder,
  ({ subtotal, tax, total }) => ({
    subtotal:
      subtotal && subtotal.length
        ? parseInt((Number(subtotal) * 100).toFixed(0))
        : null,
    tax: tax && tax.length ? parseInt((Number(tax) * 100).toFixed(0)) : null,
    total:
      total && total.length
        ? parseInt((Number(total || 0) * 100).toFixed(0))
        : null,
  })
);

const selectCurrentTransactionId = createSelector(
  selectOrder,
  (order) => order.currentTransactionId
);
const selectTransactions = createSelector(
  selectOrder,
  (order) => order.transactions
);
const selectOrderError = createSelector(
  selectOrder,
  (order) => order.orderError
);
const selectIsPosmonDisconnected = createSelector(
  selectOrder,
  (order) => order.isPosmonDisconnected
);

const selectIsPosmonReconnected = createSelector(
  selectOrder,
  (order) => order.isPosmonReconnected
);

const selectCurrentTransactionCoupons = createSelector(
  selectOrder,
  (order) => order.currentTransactionCoupons
);

const selectLastHITLOrderId = createSelector(selectOrder, (order) => {
  const lastOrderRequest = order.requestsOrder.length
    ? order.requestsOrder[order.requestsOrder.length - 1]
    : '';
  return order.requestsById[lastOrderRequest]?.orderId;
});

export {
  selectOrder,
  selectOrderValues,
  selectCurrentTransactionId,
  selectTransactions,
  selectOrderError,
  selectIsPosmonDisconnected,
  selectIsPosmonReconnected,
  selectCurrentTransactionCoupons,
  selectLastHITLOrderId,
};
