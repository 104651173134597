import { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useSound from 'use-sound';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { infoSeqIdCounter } from '../app/middleware';
import { DUMMY_RESTAURANT_CODE, TIMEOUT } from '../constants';
import { AgentTypes, EventTypes } from '../constants/event';
import {
  IHITLAgentFirstActivityTransmissionMessage,
  IHITLEventConnectionTransmissionMessage,
  messagingActions,
  sendTestSignal,
} from '../reducers/messagingSlice';
import { selectIsAIEscalation } from '../redux/features/ai/ai.selector';
import {
  selectAgentInactivityTimer,
  selectEventAPI,
  selectEventAPIAuth,
  selectTaskRouterNotificationSound,
} from '../redux/features/config/config.selector';
import { selectSessionId } from '../redux/features/sessionBoundary/sessionBoundary.selectors';
import { setSessionTimeoutDialogOpen } from '../redux/features/sessionBoundary/sessionBoundary.slice';
import {
  selectCurrentTask,
  selectIsCurrentTaskAIEnabled,
} from '../redux/features/taskRouter/taskRouter.selector';
import {
  selectIsStaffIntervention,
  selectIsWebSocketConnected,
} from '../selectors/message';
import { selectActiveRestaurantCode } from '../selectors/restaurant';
import { selectUserProfile } from '../selectors/user';
import sessionTimeoutSound from '../sounds/task_session_timeout.mp3';
import { UserDetails } from '../types';
import { post } from '../utils/api';
import logger from '../utils/logger';
import { getAgentInactivityFeatureFlag } from '../utils/session-storage';

interface IAgentActivity {
  activityType: string;
  event?: EventTypes;
}

const useActivityTracking = () => {
  const isWebSocketConnected = useAppSelector(selectIsWebSocketConnected);
  const userDetails = useAppSelector(selectUserProfile);
  const sessionId = useAppSelector(selectSessionId);
  const restaurantCode = useAppSelector(selectActiveRestaurantCode);
  const eventAPI = useAppSelector(selectEventAPI);
  const eventAPIAuth = useAppSelector(selectEventAPIAuth);
  const inactivityTimer = useAppSelector(selectAgentInactivityTimer);
  const isTRTask = useAppSelector(selectCurrentTask);
  const isAITask = useAppSelector(selectIsCurrentTaskAIEnabled);
  const isAIEscalation = useAppSelector(selectIsAIEscalation);
  const isStaffIntervention = useAppSelector(selectIsStaffIntervention);
  const [play] = useSound(sessionTimeoutSound);
  const canPlayNotificationSound = useAppSelector(
    selectTaskRouterNotificationSound
  );

  const [isFirstActivity, setIsFirstActivity] = useState<boolean>(true);
  const sentAgentFirstActivityInWS = useRef<boolean>();
  sentAgentFirstActivityInWS.current = false;

  const dispatch = useAppDispatch();

  let agentId = uuidv4();
  const successCallback = (response: any) => {
    logger.info({
      message: 'Post message to Event API successfully',
      moreInfo: JSON.stringify(response),
    });
  };

  const errorCallback = (error: any) => {
    logger.error({
      message: 'Failed to send message to Event API',
      error,
    });
  };

  const sendAgentActivity = async (agentActivity: IAgentActivity) => {
    const { id, username, email, firstName, lastName } =
      (userDetails as UserDetails) || {};

    const { activityType, event = EventTypes.agentActivity } = agentActivity;
    const payload = {
      id: uuidv4(),
      seq: infoSeqIdCounter.increment(),
      agent_id: agentId,
      agent_type: AgentTypes.HITL,
      session_id: sessionId || 'dummy_session_id',
      timestamp: new Date().toISOString(),
      event,
      data: {
        user_details: {
          id,
          username,
          email,
          firstName,
          lastName,
        },
        activity_type: activityType,
      },
      restaurant_code: restaurantCode || DUMMY_RESTAURANT_CODE,
    };

    post({
      url: `${eventAPI}/v1/event`,
      data: {
        ...payload,
      },
      headers: {
        Authorization: `Basic ${eventAPIAuth}`,
      },
      successCallback,
      errorCallback,
    });
  };

  const sendWSAgentFirstActivity = () => {
    const agentFirstActivityPayload: Partial<IHITLAgentFirstActivityTransmissionMessage> =
      {
        data: {
          message:
            'Sending agent_first_activity after websocket connection to event backend is established',
        },
      };
    sentAgentFirstActivityInWS.current = true;
    dispatch(
      messagingActions.sendAgentFirstActivity(agentFirstActivityPayload as any)
    );
  };

  const onAction = (event?: Event) => {
    if (isFirstActivity) {
      sendAgentActivity({
        activityType: event?.type || 'Event',
        event: EventTypes.agentFirstActivity,
      });

      setIsFirstActivity(false);
      if (
        getAgentInactivityFeatureFlag() &&
        isWebSocketConnected &&
        !sentAgentFirstActivityInWS.current
      ) {
        sendWSAgentFirstActivity();
      }
    } else if (!isWebSocketConnected) {
      sendAgentActivity({
        activityType: event?.type || 'Event',
      });
    }
  };

  const onPrompt = () => {
    logger.log({
      restaurantCode,
      message: `useIdleTimer: Agent session idle prompt being shown for restaurant with ai_enabled as ${isAITask}`,
    });
    if (canPlayNotificationSound) play();
    dispatch(setSessionTimeoutDialogOpen(true));
  };

  const onIdle = () => {
    logger.log({
      restaurantCode,
      message: `useIdleTimer: Agent session idle action being performed for restaurant with ai_enabled as ${isAITask}`,
    });
    dispatch(setSessionTimeoutDialogOpen(false));
    pause();
  };

  const handleAgentSessionContinuation = () => {
    logger.log({
      restaurantCode,
      message: `useIdleTimer: Agent clicked on continue session for restaurant with ai_enabled as ${isAITask}`,
    });
    dispatch(setSessionTimeoutDialogOpen(false));
    pause();
  };

  const handleStaffIntervention = () => {
    logger.log({
      restaurantCode,
      message: `useIdleTimer: Staff intervention for restaurant with ai_enabled as ${isAITask}`,
    });
    dispatch(setSessionTimeoutDialogOpen(false));
    pause();
  };

  const { pause, start } = useIdleTimer({
    onAction,
    throttle: 500,
    ...(isTRTask
      ? {
          timeout: inactivityTimer * 1000,
          promptBeforeIdle: Math.min(inactivityTimer, 10) * 1000,
          onIdle,
          onPrompt,
          startManually: true,
        }
      : null),
  });

  useEffect(() => {
    if (isWebSocketConnected) {
      if (isFirstActivity) {
        const payload: Partial<IHITLEventConnectionTransmissionMessage> = {
          data: {
            message: 'Websocket connection to event backend is established',
          },
        };
        dispatch(messagingActions.sendHITLEventConnection(payload as any));
      } else {
        if (
          getAgentInactivityFeatureFlag() &&
          !sentAgentFirstActivityInWS.current
        ) {
          sendWSAgentFirstActivity();
        }
        let timer = setTimeout(() => {
          logger.log({
            restaurantCode,
            message: `useIdleTimer being paused for restaurant`,
          });
          pause(); // Stop sending agent activity after 5000 ms after websocket connection is established
        }, TIMEOUT);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [
    isWebSocketConnected,
    isFirstActivity,
    sentAgentFirstActivityInWS.current,
  ]);

  useEffect(() => {
    dispatch(sendTestSignal());
  }, []);

  useEffect(() => {
    if (isTRTask && (!isAITask || (isAITask && isAIEscalation))) {
      logger.log({
        restaurantCode,
        message: `useIdleTimer being started for restaurant with ai_enabled as ${isAITask} and ai_escalation as ${isAIEscalation}`,
      });
      start();
    }
  }, [isTRTask, isAITask, isAIEscalation]);

  useEffect(() => {
    if (
      isStaffIntervention &&
      isTRTask &&
      (!isAITask || (isAITask && isAIEscalation))
    ) {
      handleStaffIntervention();
    }
  }, [isStaffIntervention, isTRTask, isAITask, isAIEscalation]);

  return { onIdle, handleAgentSessionContinuation };
};

export default useActivityTracking;
