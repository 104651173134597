import { FormControl, FormLabel, RadioGroup, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { CartModifierGroup } from '../../../utils/cart';
import { checkItemInTree, isItem86edToday } from '../../../utils/menu';
import { ModGroupLabel } from '../components/ModGroupLabel';
import RecursiveModGroup from '../components/RecursiveModGroup';
import SingularModChoice from '../components/SingularModChoice';
import { getModGroupItemLabel, sortAndFilterMenuItems } from "../util/helper";
import { IModGroup } from './modGroup.props';
import { hasModGroupChildren } from './modGroup.utils';

const useStyles = makeStyles<{ hasChildren: boolean}>()(({ spacing }, { hasChildren }) => {
  const mBottom = hasChildren ? spacing(1.25) : 0;
  return {
    modGroup: {
      margin: '0 !important',
      '& legend': {
        width: '100%',
      },
      width: '100%',
    },
    modGroupContainer: {
      marginBottom: mBottom
    }
  }
});

export default function SingularSelectionModGroup(props: IModGroup) {
  const { modGroup, onChange, cartItem, pathToItem } = props;
  let currentValue: string | null = null;
  let selectedModNumber = 0;
  const currentItem = checkItemInTree({
    cartItem,
    pathToItem,
  }) as CartModifierGroup;
  if (currentItem) {
    const selectedMod = currentItem?.selectedItems || [];
    currentValue = Object.values(selectedMod)[0]?.itemId || null;
    selectedModNumber = Object.values(selectedMod).length;
  }

  // sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);
  const key = [cartItem.id, modGroup.id].join('_');
  const hasChildren = hasModGroupChildren(props);
  const { classes } = useStyles({ hasChildren });

  return (
    <div key={key} className={classes.modGroupContainer}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel
            modGroup={modGroup}
            selectedModNumber={selectedModNumber}
          />
        </FormLabel>
        <RadioGroup
          row
          aria-label={modGroup.name}
          name={modGroup.name}
          value={currentValue}
          onChange={onChange(modGroup)}
        >
          {menuItems.map((child) => {
            const is86edToday = isItem86edToday(child);
            const pathToModChoice = [pathToItem, child.id].join('__');
            const isSelected = !!checkItemInTree({ cartItem, pathToItem: pathToModChoice });
            const id = child.itemId;
            const name = child.name;
            const modLabel = getModGroupItemLabel(child);
            if (child.available || is86edToday) {
              return (
                <SingularModChoice
                  key={pathToModChoice}
                  id={id}
                  name={name}
                  modLabel={modLabel}
                  is86edToday={is86edToday}
                  isSelected={isSelected}
                />
              );
            }
            return null;
          })}
        </RadioGroup>
      </FormControl>
      <RecursiveModGroup {...props} />
    </div>
  );
}
