import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectIsAIAutoMode } from '../../redux/features/ai/ai.selector';
import { selectCurrentTaskAIStatus } from '../../redux/features/taskRouter/taskRouter.selector';
import Colors from '../../utils/color';
import { VolumeSlider } from '../Audio/VolumeSlider';
import { MessageContainer } from '../Chat/MessageContainer';
import useTranscriptMessages from './useTranscriptMessages.hooks';

type TranscriptSliderAreaTypeProps = {
  isExpandedMode: boolean;
};

const useStyles = makeStyles<Theme, TranscriptSliderAreaTypeProps>(
  ({ spacing }) => ({
    transcriptSliderAreaClass: {
      position: 'absolute',
      zIndex: '5',
      height: 'calc(100% - 180px)',
      borderRight: `${spacing(0.125)} solid ${Colors.alto}`,
    },
    closeClass: {
      position: 'absolute',
      top: '0',
      right: '0',
    },
    transcriptMessagesClass: {
      overflowY: 'auto',
      height: `calc(100% - 40px)`,
      borderTop: `${spacing(0.125)} solid ${Colors.alto}`,
    },
    dividerContainerClass: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '10px',
    },
    dividerClass: {
      fontSize: '14px',
      lineHeight: '20px',
      color: Colors.guardsmanRed,
    },
    underlineClass: {
      flex: '1',
      content: '',
      padding: '0.75px',
      backgroundColor: Colors.guardsmanRed,
      margin: '5px',
      display: 'inline-block',
    },
  })
);

const TranscriptSliderArea = () => {
  const [isExpandedMode, setIsExpandedMode] = useState(false);
  const currentTaskAIStatus = useAppSelector(selectCurrentTaskAIStatus);
  const isAIAutoMode = useAppSelector(selectIsAIAutoMode);

  const {
    transcriptSliderAreaClass,
    closeClass,
    dividerContainerClass,
    transcriptMessagesClass,
    dividerClass,
    underlineClass,
  } = useStyles({
    isExpandedMode,
  });

  useEffect(() => {
    if (currentTaskAIStatus) {
      setIsExpandedMode(true);
    }
  }, []);

  useEffect(() => {
    if (!isAIAutoMode && isExpandedMode) {
      setIsExpandedMode(false);
    }
  }, [isAIAutoMode]);

  const { messagesList, sessionBufferMessagesList } = useTranscriptMessages();

  return (
    <div
      data-testid="transcript-slider-area"
      className={transcriptSliderAreaClass}
      style={{
        width: isExpandedMode ? '100%' : '360px',
        background: isExpandedMode ? Colors.white : Colors.porcelain,
      }}
    >
      <VolumeSlider />
      <div
        className={transcriptMessagesClass}
        style={{
          padding: isExpandedMode ? '32px' : '6px',
        }}
      >
        {sessionBufferMessagesList.length > 0 && (
          <MessageContainer messageList={sessionBufferMessagesList} />
        )}
        {currentTaskAIStatus && sessionBufferMessagesList.length > 0 && (
          <>
            <div className={dividerContainerClass}>
              <div className={underlineClass}></div>
              <div className={dividerClass}>With Supervised Agent</div>
              <div className={underlineClass}></div>
            </div>
          </>
        )}
        <MessageContainer messageList={messagesList} />
      </div>
      {isExpandedMode && (
        <div className={closeClass}>
          <IconButton
            aria-label="delete"
            onClick={() => setIsExpandedMode(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default TranscriptSliderArea;
