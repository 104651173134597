import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  getAllInvalidModGroups,
  flatInvalidModGroups,
  CartItem,
  getValidCartItems,
} from '../utils/cart';
import { convertToMap } from '../utils/menu';
import { GenericMap } from '../utils/types';

const selectCart = (state: RootState) => state.cart;
const selectCartModality = createSelector(selectCart, (cart) => cart.modality);
const selectCartItems = createSelector(selectCart, (cart) => cart.cartItems);
const selectCartItemsQuantity = createSelector(
  selectCart,
  (cart) => cart.cartItemsQuantity
);
const invalidModGroupsSelector = createSelector(selectCartItems, (cartItems) =>
  getAllInvalidModGroups(cartItems)
);
const selectValidCartItems = createSelector(selectCartItems, (cartItems) =>
  getValidCartItems(cartItems)
);
const selectHypothesisCartItems = createSelector(selectCart, (cart) => cart.hypothesisCartItems);

export {
  selectCart,
  selectCartItems,
  selectCartItemsQuantity,
  invalidModGroupsSelector,
  selectCartModality,
  selectValidCartItems,
  selectHypothesisCartItems
};
