import useAgentInterception from '../../../hooks/agentInterception/useAgentInterception.hooks';
import {
  AgentInterceptionType,
  messagingActions,
  TextFrameTransmissionMessage,
} from '../../../reducers/messagingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { selectActiveRestaurantDetails } from '../../../selectors/restaurant';

const useAutocompleteMessage = () => {
  const dispatch = useDispatch();
  const [currentMessage, setCurrentMessage] = useState('');
  const { sendAgentInterception } = useAgentInterception();
  const { restaurantName } = useSelector(selectActiveRestaurantDetails);
  const updateCurrentMessage = (message: string) => {
    setCurrentMessage(message);
  };
  const sendAgentInterventionEvent = (
    type: AgentInterceptionType,
    description?: string
  ) => {
    sendAgentInterception({
      data: {
        type,
        description,
      },
    });
  };
  const sendMessage = (message: string, metadata?: any, title?: string) => {
    message = message.replaceAll('{RESTAURANT_NAME}', restaurantName);
    const payload: Partial<TextFrameTransmissionMessage> = {
      data: { payload: message, status: 'final', metadata },
    };
    dispatch(messagingActions.sendMessage(payload as any));
    sendAgentInterventionEvent(AgentInterceptionType.TTS_SENT);
  };

  return {
    currentMessage,
    updateCurrentMessage,
    sendMessage,
    sendAgentInterventionEvent,
  };
};

export default useAutocompleteMessage;
