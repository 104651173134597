import { GenericMap } from '../../../utils/types';
import { CartItem } from '../../../utils/cart';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import {
  selectRestaurant,
  selectStage,
} from '../../../reducers/restaurantSlice';
import { selectMenuVersion } from '../../../reducers/menuSlice';

export interface IAutoComboState {
  autoCombinedCartItems: GenericMap<CartItem>; // Populate only when finding cart item match to form auto combo
  autoCombinedCartItemsQuantity: Record<string, number>;
}

export const initialState: IAutoComboState = {
  autoCombinedCartItems: {},
  autoCombinedCartItemsQuantity: {},
};

const autoComboSlice = createSlice({
  name: 'autoCombo',
  initialState,
  reducers: {
    setAutoCombinedCart: (
      state,
      {
        payload: { autoCombinedCartItems, autoCombinedCartItemsQuantity },
      }: PayloadAction<{
        autoCombinedCartItems: GenericMap<CartItem>;
        autoCombinedCartItemsQuantity: Record<string, number>;
      }>
    ) => {
      state.autoCombinedCartItems = autoCombinedCartItems;
      state.autoCombinedCartItemsQuantity = autoCombinedCartItemsQuantity;
    },
    resetAutoComboState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        selectRestaurant.fulfilled,
        selectStage.fulfilled,
        selectMenuVersion.fulfilled
      ),
      () => {
        return initialState;
      }
    );
  },
});

const { setAutoCombinedCart, resetAutoComboState } = autoComboSlice.actions;

export { setAutoCombinedCart, resetAutoComboState };

export default autoComboSlice.reducer;
