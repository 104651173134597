//TODO: This file along with its slice has to be moved into some folder specific to orderSlice
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../app/store';
import { CheckResponseTypes, StatusResponseTypes } from '../constants/event';
import {
  CheckTransmissionMessage,
  IStatusTransmissionMessage,
} from './messagingSlice';
import { OrderStatus, TransactionStatus } from './orderSlice.constants';
import { IOrderState } from './orderSlice.props';
import logger from '../utils/logger';

const initialOrderState: IOrderState = {
  currentSessionId: null,
  seqId: 0,
  total: '',
  subtotal: '',
  tax: '',
  orderError: '',
  currentTransactionId: null,
  transactions: {},
  currentTransactionItems: {},
  completeClickCount: {},
  canAcceptHypothesisEvents: true,
  requestsById: {},
  requestsOrder: [],
  isPosmonReconnected: false,
  isPosmonDisconnected: false,
  currentTransactionCoupons: {},
};

const processOrderStatusAndCheckResponses = (
  state: IOrderState,
  requestId: string
) => {
  const {
    orderStatusResponse,
    checkResponse,
    cartItemIds,
    isFinal,
    couponIds,
  } = state.requestsById[requestId];

  //order_status response destructuring
  const { data: orderStatusData } =
    (orderStatusResponse as IStatusTransmissionMessage) || {};
  const { status: orderStatus, result } = orderStatusData || {};
  const { transaction_id: orderTransactionId = '' } = result || {};
  const { type = '', message: statusMessage = '' } = orderStatus || {};

  //check response destructuring
  const { data: checkData } = (checkResponse as CheckTransmissionMessage) || {};
  const {
    status: checkStatus = CheckResponseTypes.unknown,
    check,
    transaction_id: checkTransactionId = '',
    coupon_apply_status,
  } = checkData || {};
  const { total = '', subtotal = '', tax = '' } = check || {};

  const transactionId = orderTransactionId || checkTransactionId;

  state.requestsById[requestId].transactionId = transactionId;

  if (
    state.requestsOrder.length &&
    state.requestsOrder[state.requestsOrder.length - 1] === requestId
  )
    state.currentTransactionId = transactionId; // Note: when order_status returns error, no transactionId will be created. currentTransactionId will be set to empty string ''

  if (orderStatusResponse && !checkResponse) {
    //order_status event is received while check event is not received
    state.requestsById[requestId].status = type;
    if (type === StatusResponseTypes.success) {
      state.orderError = '';
      state.requestsById[requestId].transactionId = transactionId;

      state.transactions[transactionId] = {
        data: null,
        status: TransactionStatus.pending, // transactionId is created but not finished
        cartItemIds,
        isFinal,
        couponIds,
      };

      if (!isFinal) {
        // currentTransactionItems will be reset to {} when Finish Order is clicked.
        // Only the transaction level status is needed to indicate the status of the final order in the dollar sign visual indicator.
        for (let cartItemId of cartItemIds) {
          if (
            state.currentTransactionItems[cartItemId] !== OrderStatus.completed
          ) {
            state.currentTransactionItems[cartItemId] = OrderStatus.pending;
          }
        }

        // Change the coupon apply status to pending
        couponIds.forEach((id) => {
          if (state.currentTransactionCoupons[id] !== OrderStatus.completed) {
            state.currentTransactionCoupons[id] = OrderStatus.pending;
          }
        });
      }
    } else if (type === StatusResponseTypes.error) {
      state.orderError = statusMessage;
      if (
        !state.errorCartItemId ||
        !cartItemIds.find((id: number) => id === state.errorCartItemId)
      ) {
        state.errorCartItemId = cartItemIds[cartItemIds.length - 1];
      }
      if (state.errorCartItemId) {
        state.currentTransactionItems[state.errorCartItemId] =
          OrderStatus.failed;
      }
    }
  } else {
    //One of the below two scenarios:
    //(1) order_status event is received and check event is also received
    //(2) order_status event is not received but check event is received

    state.orderError = '';
    state.requestsById[requestId].status = checkStatus;
    state.transactions[transactionId] = {
      data: checkData,
      status:
        checkStatus === CheckResponseTypes.ok
          ? TransactionStatus.completed
          : TransactionStatus.error,
      cartItemIds,
      isFinal,
      couponIds,
    };

    if (!isFinal) {
      // currentTransactionItems will be reset to {} when Finish Order is clicked.
      // Only the transaction level status is needed to indicate the status of the final order in the dollar sign visual indicator.
      for (let cartItemId of state.transactions[transactionId].cartItemIds) {
        if (
          !state.currentTransactionItems[cartItemId] ||
          state.currentTransactionItems[cartItemId] !== OrderStatus.completed
        ) {
          state.currentTransactionItems[cartItemId] =
            checkStatus === CheckResponseTypes.ok
              ? OrderStatus.completed
              : OrderStatus.failed;
        }
      }
    }

    if (checkStatus === CheckResponseTypes.ok) {
      if (state.errorCartItemId) {
        state.currentTransactionItems[state.errorCartItemId] =
          OrderStatus.completed; // Set the status of previous error cart item to completed if the following transaction succeeds
      }
      state.errorCartItemId = undefined;

      state.total = total || state.total;
      state.subtotal = subtotal || state.subtotal;
      state.tax = tax || state.tax;

      // POSMON will only send coupon_apply_status if the order is sent successfully
      if (coupon_apply_status && !isFinal) {
        couponIds.forEach((id) => {
          const couponApplyStatus = coupon_apply_status.find(
            (couponApplyStatus) => couponApplyStatus.id === id
          );

          state.currentTransactionCoupons[id] = couponApplyStatus?.applied
            ? OrderStatus.completed
            : OrderStatus.pending;
        });
      }
    } else {
      if (
        !state.errorCartItemId ||
        !cartItemIds.find((id) => id === state.errorCartItemId)
      ) {
        state.errorCartItemId = cartItemIds[cartItemIds.length - 1]; // Set the last cart item id to be the errorCartItemId
      }
      state.currentTransactionItems[state.errorCartItemId] = OrderStatus.failed;
      state.orderError = checkData.error ? checkData.error : '';
    }
  }
};

const setLastOrderItemsFailed = (state: IOrderState, errorMessage: string) => {
  const orderRequestsLength = state.requestsOrder.length;
  if (orderRequestsLength) {
    state.orderError = errorMessage;
    const lastRequestId = state.requestsOrder[orderRequestsLength - 1] || '';
    const cartItemIds = state.requestsById[lastRequestId].cartItemIds;
    if (
      !state.errorCartItemId ||
      !cartItemIds.find((id: number) => id === state.errorCartItemId)
    ) {
      state.errorCartItemId = cartItemIds[cartItemIds.length - 1];
    }
    if (state.errorCartItemId) {
      state.currentTransactionItems[state.errorCartItemId] = OrderStatus.failed;
    }
  }
};

const extractOrderId = (rootState: RootState) => {
  const {
    order: { currentSessionId: hitlOrderId },
    sessionBoundary: {
      orderMessageOrderId: aiOrderId,
      sessionId: outerSessionId,
    },
  } = rootState;
  /**
   * orderId is populated as follows:
   *
   * aiOrderId      -> ID received via AI "order" message       (AI can processes only first order, multiple orders can't be processed by AI)
   * hitlOrderId    -> ID of the last order sent from HITL      (First order: First item request it will be space, other item requests it will be outerSessionId)
   *                                                            (When Cancel/Finish order is clicked, this value will be populated with new uuid)
   * outerSessionId -> ID of the session when car enter happens (This will be same until car exit happens)
   * uuid           -> New UUID                                 (This value would never be used because outerSessionId will always have a value)
   */
  const orderId = aiOrderId || hitlOrderId || outerSessionId || uuidv4();
  logger.log({
    message: `Use order id: ${orderId}`,
    moreInfo: JSON.stringify({
      aiOrderId: aiOrderId,
      hitlOrderId: hitlOrderId,
      outerSessionId: outerSessionId,
    }),
  });
  return orderId;
};

export {
  extractOrderId,
  initialOrderState,
  processOrderStatusAndCheckResponses,
  setLastOrderItemsFailed,
};
